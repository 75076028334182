import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  // registerDealer(data: any) {
  //   var body = JSON.stringify(data);
  //   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.httpClient.post(environment.api_root_url + "/api/Common/saveNewDealer", body, { headers: header });
  // }

  registerDealer(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/RequestDealer_Info", body, { headers: header });
  }

  
  registerNewDealer(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/Dealerreq", body, { headers: header });
  }
  updatepassword(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/Update_Password ", body, { headers: header });
  }

  postOfferAmount(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/postOfferAmount", body, { headers: header });
  }

  sendMessage(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/saveContact", body, { headers: header });
  }

  sendInterestedMessage(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.api_root_url + "/api/Common/interest_vehi", body, { headers: header });
  }

  getUserClaims(accessToken) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + accessToken,
    });
    return this.httpClient.get(environment.api_root_url + "/api/Common/GetUserClaims", { headers: header });
  }

  getPostOfferDetails(leadId, userId) {
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get(environment.api_root_url + "/api/Common/Get_postoffer_details/"+leadId+"/"+userId, { headers: header });
  }

  saveSetting(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.post(environment.api_root_url + "/api/Common/SaveSettings", body, { headers: header });
  }
  createuser(data: any) {
    var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.post(environment.api_root_url + "/api/Common/EditUser_details", body, { headers: header });
  }
  DealearAccept(data: any) {
    console.log("sksds");
    var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.post(environment.api_root_url + "/api/Common/DealersAccept", body, { headers: header });
  }
  Dealearupdate(data: any) {
    console.log("sksds");
    var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.post(environment.api_root_url + "/api/Common/EditDealer_details", body, { headers: header });
  }
  getSetting(userId,id) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/GetSettings/"+userId+"/"+id, { headers: header });
  }

  removeSetting(userId,id) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/deletesettings/"+userId+"/"+id, { headers: header });
  }

  
  UserDetials(userId,dealerid) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/GetUserDetails/"+userId+"/"+dealerid, { headers: header });
  }
  AdminRequest(dealerid) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/GetAllRequestedDealer/"+dealerid, { headers: header });
  }
  checkpassword(userId) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/Check_user_link/"+userId, { headers: header });
  }
  DealerRequest(dealerid)
  {
    var header = new HttpHeaders({
      'Content-Type': 'application/json' ,
      'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
   return this.httpClient.get(environment.api_root_url + "/api/Common/GetDealersDetails/"+dealerid, { headers: header }); 
  }
  deleteuser(userId) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/Delete_UseInfo/"+userId, { headers: header });
  }
  deletedealer(dealerId) {
    // var body = JSON.stringify(data);
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/Delete_DealerInfo/"+dealerId, { headers: header });
  }
  deletedealerreq(dealerId) {
    // var body = JSON.stringify(data);
    console.log("sksds");
    var header = new HttpHeaders({
       'Content-Type': 'application/json' ,
       'authorization': 'Bearer ' + localStorage.getItem('access_token'),});
    return this.httpClient.get(environment.api_root_url + "/api/Common/Delete_Request_DealerInfo/"+dealerId, { headers: header });
  }
}
