import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, RequestOptions, RequestMethod, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: Http,
    private router: Router
  ) { }

  login(username: any, password: any) {
    var data = "username=" + username + "&password=" + password + "&grant_type=password";
    var headerOptions = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(environment.api_root_url + '/token', data, requestOptions).pipe(map(x => x.json()));
  }

  isLoggedIn() {
    return localStorage.getItem('access_token') ? true : false;
  }

  logout() {
    localStorage.removeItem('access_token');

    this.router.navigate(['/login']);
  }
}
