import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() activeMenu: any;

  categoryOpen: boolean = false;
  loggedIn: boolean = false;
  userrole:any;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    this.userrole =  localStorage.getItem("userrole"); 


  }

  onToggleCategory() {
    if(this.categoryOpen) {
      this.categoryOpen = false;
    } else {
      this.categoryOpen = true;
      console.log('Opening category...');
    }
  }
  cleardata()
  {
    localStorage.removeItem("selectedModel");
    localStorage.removeItem("selectedMake");
    localStorage.removeItem("selectedMinYear");
    localStorage.removeItem("selectedMaxYear");
    localStorage.removeItem("backtohome");
    localStorage.removeItem("selectid");
    localStorage.removeItem("selectedStatus");
  }
  logout() {
    this.authService.logout();
  }
}
