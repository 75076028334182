import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "../services/api.service";
// import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  displayedColumns: string[] = [
    "Firstname",
    "LastName",
    "UserName",
    "SendBOS",
    "actions",
  ];

  dataSource: MatTableDataSource<any>;
  public CreateUser: FormGroup;
  @ViewChild('scheduledOrdersPaginator') paginator: MatPaginator;
  loader: boolean = false;
  allUsersList: any = [];
  activeTab : number = 1;
  bttntext : string = 'Add';
  userId:any = "";
  dearlerid:any;
  dearlername:any;
  hide: boolean = true;
  userSubmitted: boolean = false;
  constructor(
    private apiService: ApiService,
    public _fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.CreateUser = this._fb.group({
      UserId: [""],
      FName: ["", Validators.required],
      LName: [""],
      EmailId: ["", Validators.required],
      Password: ["", Validators.required],
      UserName: ["", Validators.required],
      EmailBos: [""],
      DealerID: [""],
      PhoneNo: [""],
      Role: [""],
      Status: [""],
    });
    this.dearlerid= localStorage.getItem("Dealerid");
    this.dearlername= localStorage.getItem("DealerName")?localStorage.getItem("DealerName"):"";
    this.getUserDetials(0,localStorage.getItem("Dealerid"));
  }
  getUserDetials(userid, id) {
    this.loader = true;
    this.apiService.UserDetials(userid, id).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.loader = false;
        this.allUsersList = resultData;
        this.dataSource = new MatTableDataSource(this.allUsersList);
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      },
      (error) => {}
    );
  }
  // ngAfterViewInit() {
  //   setTimeout(() => (this.dataSource.paginator = this.paginator));
  // }
  Onsubmit() {
    console.log("this.userId",this.userId)
    this.userSubmitted= true;
    this.loader = true;

    console.log(this.CreateUser.value.EmailBos);
    if (
      this.CreateUser.value.EmailBos != "" && this.CreateUser.value.EmailBos == true
    ) {
      this.CreateUser.value.EmailBos = "1";
    } else {
      this.CreateUser.value.EmailBos = "0";
    }
    let data = {
      FName: this.CreateUser.value.FName,
      LName: this.CreateUser.value.LName,
      UserName: this.CreateUser.value.UserName,
      Password: this.CreateUser.value.Password,
      EmailId: this.CreateUser.value.EmailId,
      EmailBos: this.CreateUser.value.EmailBos,
      UserId: this.userId != ""?this.userId :"0",
      DealerID: localStorage.getItem("Dealerid"),
      PhoneNo: "0",
      Status: "Y",
      Role:  localStorage.getItem("userrole"),
    };
    if (this.CreateUser.invalid) {
      this.loader = false;
      return;
    }
    this.apiService.createuser(data).subscribe((resultData: any) => {
      this.loader = false;
      console.log(resultData);
      if (resultData.status == "1") {
        this.toastr.success(resultData.msg, null, {
          timeOut: 3000,
        });
        this.getUserDetials(0, localStorage.getItem("Dealerid"));
        this.bttntext="Add";

      }
      if (resultData.status == "0") {
        this.toastr.error(resultData.msg, null, {
          timeOut: 3000,
        });
      }
      this.CreateUser.reset();
    },(error) => {});
  }
  edituser(userId,id) {
    
    
    this.apiService.UserDetials(userId, id).subscribe(
      (resultData: any) => {
        console.log(resultData);
       // this.allUsersList = resultData
        this.CreateUser.get("FName").setValue(resultData[0].FName);
        this.CreateUser.get("LName").setValue(resultData[0].LName);
        this.CreateUser.get("UserName").setValue(resultData[0].UserName);
        this.CreateUser.get("Password").setValue(resultData[0].Password);
        this.CreateUser.get("EmailId").setValue(resultData[0].EmailId);
        if(resultData[0].EmailBos == 1)
        {
          this.CreateUser.get("EmailBos").setValue(true);
        }
        
        this.userId = resultData[0].UserId;
        this.activeTab = 1;
        this.bttntext = "Update";
      },
      (error) => {}
    );
  }
  Deleteuser(userId) {
    this.apiService.deleteuser(userId).subscribe(
      (resultData: any) => {
        if (resultData.status == "1") {
          this.toastr.success("Deleted successfully", null, {
            timeOut: 3000,
          });
          this.getUserDetials(0, localStorage.getItem("Dealerid"));
      
  
        }
      },
      (error) => {}
    );
  }
  cleartab()
  {
    this.bttntext ='Add';
    this.CreateUser.reset();
  }
  applyFilter(FilterValue: string) {
    this.dataSource.filter = FilterValue.trim().toLowerCase();
    
  }
  clearMsg() {
    this.userSubmitted = false;
  }
}
