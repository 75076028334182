<!-- START FOOTER -->
<footer class="bg_gray">
    <div class="footer_top small_pt pb_20">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="widget">
                        <div class="footer_logo">
                            <a href="#"><img
                                    src="../../../../assets/images/logo_dark.png"
                                    alt="logo" /></a>
                        </div>
                        <p class="mb-3">
                            AutoBuy Direct provides the simplest and fastest way to buy Car, Truck, SUV, Motorcycle or Commercial Vehicle!
                        </p>
                        <ul class="contact_info">
                            <li>
                                <i class="ti-email"></i>
                                <a href="mailto:info@wepaythemax.com">info@wepaythemax.com</a>
                            </li>
                            <li>
                                <i class="ti-mobile"></i>
                                <a href="tel:+15613290988">561-329-0988</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="widget" style="float: right;">
                        <h6 class="widget_title">Useful Links</h6>
                        <ul class="widget_links">
                            <li><a [routerLink]="['/inventory']">Inventory</a></li>
                            <li><a [routerLink]="['/contact']">Contact Us</a></li>
                            <li><a [routerLink]="['/login']">Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <p class="mb-lg-0 text-center">© 2020 All Rights Reserved by
                        Autobuy Direct</p>
                </div>
                <div class="col-lg-4 order-lg-first"></div>
            </div>
        </div>
    </div>
</footer>
<!-- END FOOTER -->