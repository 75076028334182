import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() vehicle: any;
  @Input() loggedIn: any;
  @Input() selectedStatus: any;

  constructor() {
  }

  ngOnInit(): void {

    this.vehicle['timeAgo'] = new Date(this.vehicle['appraiseAt'] * 1000);
  }

}
