import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    console.log("filterText",filterText)
    if (!list) {
      return [];
    }
    if (!filterText) {
      return list;
    }
    return list ? list.filter(item => item.leadId.search(new RegExp(filterText, 'i')) > -1) : [];
  }

}
