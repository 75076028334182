import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertCommaPipe'
})
export class InsertCommaPipePipe implements PipeTransform {

  transform(value: string): string {
    let regex = "(\\d)(?=(\\d{3})+$)";

    return value ? value.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,") : value;
  }

}
