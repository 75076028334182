<!-- START HEADER -->
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
    <!-- End Google Tag Manager -->
    
    <!-- Google Tag Manager (noscript) -->
    <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
    <!-- End Google Tag Manager (noscript) -->
<div class="header_sticky_bar d-none" style="height: 67px;"></div>
<header class="header_wrap fixed-top header_with_topbar">
    <div class="bottom_header dark_skin main_menu_uppercase" style="box-shadow: 0 0 7px rgba(0,0,0,0.1);">
    	<div class="container">
            <nav class="navbar navbar-expand-lg"> 
                <a class="navbar-brand" href="/" (click)="cleardata()">
                    <img class="logo_dark" src="assets/images/logo_dark.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false"> 
                    <span class="ion-android-menu"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li *ngIf="loggedIn && userrole == 0" style="margin-top: 11px;">
                            <a [routerLink]="['/admin-panel']">
                                <button class="btn btn-primary switch-to-webiste">SWITCH TO ADMIN</button>
                            </a>
                        </li>
                        <li><a class="nav-link nav_item" [ngClass]="{active: activeMenu == 'INVENTORY' ? true : false}" [routerLink]="['/inventory']" >Inventory</a></li>
                        <li><a class="nav-link nav_item" [ngClass]="{active: activeMenu == 'CONTACT' ? true : false}" [routerLink]="['/contact']">Contact Us</a></li>
                        <li *ngIf="loggedIn"><a class="nav-link nav_item" [ngClass]="{active: activeMenu == 'SETTING' ? true : false}" [routerLink]="['/setting']">My Settings</a></li>
                        <li *ngIf="loggedIn && userrole == 1"><a class="nav-link nav_item" [ngClass]="{active: activeMenu == 'CREATEUSER' ? true : false}" [routerLink]="['/create-user']">User Management</a></li>
                        <li *ngIf="!loggedIn"><a [routerLink]="['/login']"  class="nav-link nav_item" title="Login" [ngClass]="{'login-active': activeMenu == 'LOGIN' ? true : false}"><i class="linearicons-user"></i> Login</a></li>
                        <li *ngIf="loggedIn"><a style="cursor: pointer;" (click)="logout();"  class="nav-link nav_item" title="Logout"><i class="linearicons-user"></i> Logout</a></li>
                    </ul>
                </div>
                <!-- <ul class="navbar-nav attr-nav align-items-center">
                    <li *ngIf="!loggedIn"><a [routerLink]="['/login']"  class="nav-link" title="Login" [ngClass]="{'login-active': activeMenu == 'LOGIN' ? true : false}"><i class="linearicons-user"></i> Login</a></li>
                    <li *ngIf="loggedIn"><a style="cursor: pointer;" (click)="logout();"  class="nav-link" title="Logout"><i class="linearicons-user"></i> Logout</a></li>
                </ul> -->
            </nav>
        </div>
    </div>
</header>
<!-- END HEADER -->