import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  username: string;
  password: string;
  loginSubmitted: boolean = false;
  loggingIn: boolean = false;
  loginError: boolean = false;
  leadId: any;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.leadId = params.get('leadId');
      console.log(this.leadId);
    });
  }

  ngOnInit(): void {
    localStorage.setItem('scrollPosition', '0');

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    console.log(this.loginForm.value.username);
    console.log(this.loginForm.value.password);
    this.loginSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loggingIn = true;
    this.authService.login(this.loginForm.value.username, this.loginForm.value.password).subscribe((resultData) => {
      console.log("ResultData: ", resultData);
      if (resultData['access_token']) {
        // this.loggingIn = false;
        localStorage.setItem('access_token', resultData['access_token']);
        this.getUserClaims(resultData['access_token']);
      }
    }, (error) => {
      this.loggingIn = false;
      this.loginError = true;
      console.log(error);
    });
  }

  clearMsg() {
    this.loginSubmitted = false;
  }

  getUserClaims(accessToken) {
    this.apiService.getUserClaims(accessToken).subscribe((resultData) => {
      console.log("User Claims: ", resultData["userrole"]);
      if (resultData["result"] == "SUCCESS") {
        localStorage.setItem("userId", resultData["userid"]);
        localStorage.setItem("userrole", resultData["userrole"]);
        localStorage.setItem("Dealerid", resultData["dealerid"]);
        localStorage.setItem("DealerName",resultData["DealerName"])
        if (this.leadId != "" && this.leadId != null) {
          console.log(resultData["userrole"]);
          this.router.navigateByUrl('/detail/' + this.leadId);
        }
        else if (resultData["userrole"] == "0") {
          console.log(resultData["userrole"]);
          this.router.navigateByUrl('/admin-panel');
        }
        else if (resultData["userrole"] == "1") {
          this.router.navigateByUrl('/create-user');
        }
        else {
          this.router.navigateByUrl('/inventory');
        }
      }
    }, (error) => {
      console.log(error);
    });
  }

}
