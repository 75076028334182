<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'PASSWORD'"></app-header>

<div class="main_content">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">

                <div class="col-xl-6 col-md-10 custom-loader" *ngIf="loading">
                    <img src="../../assets/images/loader.gif" height="300" alt="">
                </div>

                <div class="col-xl-6 col-md-10" *ngIf="!loading">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div *ngIf="!expired">
                            <div class="heading_s1">
                                <h3>Create Password</h3>
                            </div>
                            <form method="post" [formGroup]="changepasswordForm" (ngSubmit)="onSubmit()">
                                <!-- <div class="form-group">
                                    <input type="password" (change)="clearMsg();" class="form-control" formControlName="current_pwd"
                                        placeholder="Current Password">
                                    <div *ngIf="onsubmitbtn &&
                                    changepasswordForm.controls.current_pwd.invalid">
                                        <span class="error-msg"
                                            *ngIf="changepasswordForm.controls.current_pwd.errors.required">Current Password
                                            is required</span>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <input class="form-control" (change)="clearMsg();" [type]="hide ? 'password' : 'text'"  formControlName="new_pwd" placeholder="New Password">
                                    <mat-icon  (click)="hide = !hide" style="float: right;margin-top: -33px;
                                    margin-right: 10px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <div *ngIf="onsubmitbtn &&
                                        changepasswordForm.controls.new_pwd.invalid">
                                        <span class="error-msg" *ngIf="changepasswordForm.controls.new_pwd.errors.required">New
                                            Password
                                            is required</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" (change)="clearMsg();" [type]="hide_cp ? 'password' : 'text'" formControlName="confirm_pwd"
                                        placeholder="Confirm Password">
                                        <mat-icon  (click)="hide_cp = !hide_cp" style="float: right;margin-top: -33px;
                                        margin-right: 10px;">{{hide_cp ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <div *ngIf="onsubmitbtn &&
                                        changepasswordForm.controls.confirm_pwd.invalid">
                                        <span class="error-msg"
                                            *ngIf="changepasswordForm.controls.confirm_pwd.errors.required">New Password
                                            is required</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-fill-out btn-block"
                                        name="pswdchange">Submit</button>
                                </div>
                            </form>
                           </div>

                            <div *ngIf="expired" class="thankyou_content">
                                <div class="row tick-symbol">
                                    <img class="img-tick" src="../../assets/images/warning.jpg">
                                </div>
                                <div class="row">
                                    <div class="col-md-12 reg-succ">
                                        <h3>Link has been expired.</h3>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-12 cont-team">
                                        <h6>Our team will contact you soon</h6>
                                        <h6>Thank you</h6>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>