<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'INVENTORY'"></app-header>
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
    <!-- End Google Tag Manager -->
    
    <!-- Google Tag Manager (noscript) -->
    <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
    <!-- End Google Tag Manager (noscript) -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container">
        <!-- STRART CONTAINER -->
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="page-title" style="display: flex;">
                    <i class="fas fa-arrow-left arrow-back" (click)="navToHome();"><span
                            class="back-txt">BACK</span></i>
                    <h1 style="margin-top: -2px;">Vehicle Details</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item"><a [routerLink]="['/inventory']">Inventory</a></li>
                    <li class="breadcrumb-item active">Vehicle Details</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>

<div class="section">
    <div class="container">

        <div class="row">
            <div class="colo-md-12 col-12 custom-loader" *ngIf="!vehicleLoaded">
                <img src="../../assets/images/loader.gif" height="300" alt="">
            </div>
        </div>

        <div class="row" *ngIf="vehicleLoaded">
            <div class="col-lg-12">
                <div class="row shop_container">
                    <div class="col-md-6 col-6">
                        <div class="product">
                            <span class="pr_flash ribbon black">{{vehicle['location']}}</span>
                            <div id="carouselExampleControls" class="carousel
                                slide light_arrow" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div *ngFor="let leadImage of leadImages;let
                                        i=index" class="carousel-item
                                        {{leadImage[2]}}
                                        background_bg" style="background-image:
                                        url({{leadImage[1]}});background-position:
                                        bottom;height: 400px;cursor: pointer;" (click)="openGallery(i)">
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                    data-slide="prev"><i class="ion-chevron-left"></i></a>
                                <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                    data-slide="next"><i class="ion-chevron-right"></i></a>
                                <div style="height: auto;display: flex;
                                    position: relative;
                                    justify-items: center;
                                    align-items: center;height:
                                    75px;padding-right: 8px;">

                                    <ol class="carousel-indicators
                                        indicators_style1 thumb-ct" style="overflow: scroll;margin-bottom:
                                        0;">
                                        <li *ngFor="let leadImage of leadImages;
                                            let position=index;" class="{{leadImage[2]}}"
                                            [attr.data-target]="'#carouselExampleControls'"
                                            [attr.data-slide-to]="position" style="position: relative;height:
                                            50px;width: 50px;background-image:
                                            url({{leadImage[1]}});background-size:
                                            cover;">
                                            <div style="height:
                                                50px;width:50px;"></div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-6">
                        <div class="product-detail">
                            <span class="vin-ct" *ngIf="vehicle['vin']" (click)="copyVin(vehicle['vin'])">
                                <span>{{vehicle['vin']}}</span>
                                <span class="press-copy-text">(PRESS TO COPY)</span>
                            </span>
                            <h2 class="product-name">{{vehicle['year']}}
                                {{vehicle['make']}} {{vehicle['model']}}</h2>
                            <p class="product-style" *ngIf="vehicle['style']">{{vehicle['style']}}</p>
                            <div style="display: inline-block;margin-top:
                                -25px;margin-bottom: 16px;">
                                <span class="mileage">
                                    <i class="fas fa-tachometer-alt" style="margin-right: 8px;color:
                                        #0078D3;"></i>{{vehicle['mileage'] |
                                    insertCommaPipe}} mi
                                </span>
                                <span class="mileage ref-ct" style="margin-left:
                                    8px;">#{{vehicle['leadId']}}</span>
                                <div class="vehicle-price-ct showroom" style="display: contents;">
                                    <span *ngIf="selectedStatus != 'sold' &&
                                        vehicle['status'] == 2">
                                        <i class='fas fa-car' style="color: #0078D3;font-size:
                                            20px;margin-right: 12px;margin-left:
                                            16px;"></i>
                                    </span>

                                    <span *ngIf="vehicle['status'] == 3">
                                        <!-- <i class='fas fa-dollar-sign'
                                            style="color:
                                            #0078D3;font-size:
                                            20px;margin-right: 12px;margin-left:
                                            16px;"></i> -->
                                        <svg version="1.1" id="elp-badge" class="badge"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="10 10 289.1 289.1" enable-background="new 0 0 189.1
                                            189.1" style="background-color:#00BB66" xml:space="preserve">
                                            <!--<circle class="circle" fill="#28BDEE" cx="144.5" cy="144.5" r="144.5"/>-->
                                            <path id="SVGID_x5F_2_x5F_" fill="none" d="M31.1,129c10.4-62.1,69.2-104,131.4-93.6s104,69.2,93.6,131.4
                                                c-10.4,62.1-69.2,104-131.4,93.6C62.5,249.9,20.6,191.1,31.1,129z" />

                                            <text class="badge-percentage" transform="matrix(0.9962
                                                    0.1256 -0.1056 0.9862 148 170)" fill="white" font-weight="bold"
                                                font-size="55" style="color: white;" text-anchor="middle">{{ "SOLD"
                                                }}</text>
                                            <path id="SVGID_x5F_1_x5F_" fill="none" d="M263.9,172.8c11-65.5-33.2-127.6-98.7-138.6S37.5,67.4,26.5,133s33.2,127.6,98.7,138.6
                                                    C190.8,282.6,252.9,238.4,263.9,172.8z" />

                                        </svg>
                                    </span>

                                    <!-- <span *ngIf="!vehicle['bought']">
                                        <i class="fas fa-hourglass-half fa-spin"
                                            style="color: #0078D3;font-size: 20px;margin-right: 12px;margin-left: 16px;"></i>
                                        <span class="time-ago" style="color: black;font-weight: 300;">Inprogress from last
                                            {{ vehicle['timeAgo'] | relativeTime }}</span>
                                    </span> -->
                                    <svg *ngIf="vehicle['status'] == 1" version="1.1" id="elp-badge" class="badge"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="10 10 289.1 289.1"
                                        enable-background="new 0 0 189.1 189.1" xml:space="preserve">
                                        <!--<circle class="circle" fill="#28BDEE" cx="144.5" cy="144.5" r="144.5"/>-->
                                        <path id="SVGID_x5F_2_x5F_" fill="none" d="M31.1,129c10.4-62.1,69.2-104,131.4-93.6s104,69.2,93.6,131.4
                                            c-10.4,62.1-69.2,104-131.4,93.6C62.5,249.9,20.6,191.1,31.1,129z" />
                                        <text text-anchor="middle">
                                            <textPath xlink:href="#SVGID_x5F_2_x5F_" startOffset="185">
                                                <tspan class="badge-header" fill="#7b7a7a" font-size="31">PENDING
                                                </tspan>
                                            </textPath>
                                        </text>
                                        <text class="badge-percentage" transform="matrix(0.9962 0.1256
                                                -0.1056 0.9862 148 120)" fill="#000000" font-weight="bold"
                                            font-size="55" text-anchor="middle">{{
                                            vehicle['timeAgo'] |
                                            relativeTime }}</text>
                                        <text class="badge-percentage" transform="matrix(0.9962 0.1256
                                                -0.1056 0.9862 148 180)" fill="#000000" font-weight="bold"
                                            font-size="55" text-anchor="middle">{{
                                            vehicle['timeAgo'] |
                                            relativeTimeDay }}</text>
                                        <path id="SVGID_x5F_1_x5F_" fill="none" d="M263.9,172.8c11-65.5-33.2-127.6-98.7-138.6S37.5,67.4,26.5,133s33.2,127.6,98.7,138.6
                                                C190.8,282.6,252.9,238.4,263.9,172.8z" />
                                        <text text-anchor="middle">
                                            <textPath xlink:href="#SVGID_x5F_1_x5F_" startOffset="575">
                                                <tspan class="badge-footer" fill="#7b7a7a" font-size="31">NEGOTIATION
                                                </tspan>
                                            </textPath>
                                        </text>
                                    </svg>
                                    <!-- <img *ngIf="vehicle['bought']" src="/assets/images/inventory.png" alt="">
                                    <img *ngIf="!vehicle['bought']" src="/assets/images/inprogress.png" alt=""> -->
                                </div>
                            </div>

                            <h1 class="vehicle-price-ct offer-amt-ct" *ngIf="offerAmount != null">{{offerAmount
                                | convertAmount}} <span class="pricetag">Your Offer</span></h1>
                            <!-- <div *ngIf="vehicle['soldstatus'] == false"
                                        class="col-md-12 col-12" style="padding:
                                        0;box-shadow: 0 0 7px
                                        rgba(0,0,0,0.1); margin-top: 16px;">
                                        <div style="margin-bottom: 10px;">
                                            <button type="submit"
                                                *ngIf="!loggedIn" class="btn
                                                btn-primary btn-block"
                                                routerLink="/postlogin/{{vehicle['leadId']}}"
                                                routerLinkActive="router-link-active">
                                                POST AN OFFER
                                            </button>
                                        </div>
                                        <div class="product-detail"
                                            style="background-color: black;">
                                            <div class="title-contact"
                                                *ngIf="loggedIn &&
                                                !offerExists">POST AN OFFER</div>
                                        </div>
                                    </div> -->
                            <!-- <h1 class="vehicle-price-ct offer-amt-ct"
                                        *ngIf="offerAmount != null">{{offerAmount
                                        | convertAmount}} <span
                                            class="pricetag">Your Offer</span></h1> -->
                            <div class="col-md-12 col-12" style="padding:
                                        0;box-shadow: 0 0 7px
                                        rgba(0,0,0,0.1); margin-top: 16px;" *ngIf="selectedStatus != 'sold' && userrole !=0">
                                <div style="margin-bottom: 10px;" *ngIf="!loggedIn && selectedStatus
                                            != 'sold' && userrole !=0">
                                    <button type="submit" *ngIf="!loggedIn &&
                                                selectedStatus != 'sold' && userrole !=0" class="btn
                                                btn-primary btn-block" routerLink="/postlogin/{{vehicle['leadId']}}"
                                        routerLinkActive="router-link-active">
                                        POST AN OFFER
                                    </button>
                                </div>
                                <div class="product-detail" style="background-color: black;">
                                    <div class="title-contact" *ngIf="loggedIn &&
                                                !offerExists && selectedStatus
                                                != 'sold' && userrole !=0" >POST AN OFFER</div>
                                </div>
                                <div class="product-detail">
                                    <div class="cont-form" [ngStyle]="{padding: loggedIn ?
                                                '16px' : 'none'}">
                                        <form *ngIf="loggedIn &&
                                                    selectedStatus != 'sold' && userrole !=0" method="post" [formGroup]="offerForm"
                                            (ngSubmit)="saveOfferAmount()">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <p *ngIf="successMsg" style="color:
                                                                    green;">{{successMsg}}</p>
                                                        <label for="exampleInputEmail1" *ngIf="loggedIn
                                                                    &&
                                                                    !offerExists">
                                                            <span>
                                                                Enter An Amount
                                                            </span>
                                                        </label>
                                                        <div class="input-group
                                                                    mb-3" *ngIf="loggedIn
                                                                    &&
                                                                    !offerExists">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"
                                                                    id="basic-addon1">$</span>
                                                            </div>
                                                            <input (change)="clearMsg();" (focus)="clearMsg();"
                                                                type="number" class="form-control" id="offerAmount"
                                                                aria-describedby="offerAmount"
                                                                formControlName="offerAmount"
                                                                placeholder="Enter your amount">
                                                        </div>
                                                        <div *ngIf="offerSubmitted
                                                                    &&
                                                                    offerForm.controls.offerAmount.invalid" style="margin-top:
                                                                    8px;">
                                                            <span class="error-msg"
                                                                *ngIf="offerForm.controls.offerAmount.errors.required">Offer
                                                                amount
                                                                is
                                                                required</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" class="btn
                                                        btn-primary btn-block" *ngIf="loggedIn &&
                                                        !offerExists">Post
                                                Offer</button>
                                            <button type="button" class="btn btn-primary
                                                        btn-block" (click)="changePostOfferStatus();" *ngIf="loggedIn &&
                                                        offerExists">
                                                Change Offer
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row card" *ngIf="vehicleLoaded && showQuickView" style="padding: 0;padding-bottom:
                    8px;">
            <div class="col-12">
                <div style="color:
                            #840e19;font-weight: 600;padding: 16px 0px;">
                    QUICK VIEW
                </div>
                <div class="col-6" style="width: 50% !important;
                            float: left;">
                    <ul class="quickview">

                        <div *ngFor="let driversideInfo of
                                    driverSideQv">
                            <li *ngIf="driversideInfo.value != 'GOOD
                                        CONDITION' && driversideInfo.value !=
                                        ''">
                                <!-- <span *ngIf="driversideInfo.value != 'Good Condition'">{{driversideInfo.value}}</span> -->
                                <span>Driver side </span>{{
                                driversideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                driversideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <div *ngFor="let frontsideInfo of frontSideQv">
                            <li *ngIf="frontsideInfo.value != 'GOOD
                                        CONDITION' && frontsideInfo.value !=
                                        ''">
                                <span>Front side </span> {{
                                frontsideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                frontsideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <div *ngFor="let passengersideInfo of
                                    passengerSideQv">
                            <li *ngIf="passengersideInfo.value != 'GOOD
                                        CONDITION' && passengersideInfo.value !=
                                        ''">
                                <span>Passenger side </span> {{
                                passengersideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                passengersideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <div *ngFor="let rearsideInfo of rearSideQv">
                            <li *ngIf="rearsideInfo.value != 'GOOD
                                        CONDITION' && rearsideInfo.value != ''">
                                <span>Rear </span> {{ rearsideInfo.key |
                                titlecase |
                                replaceUnderscorePipe }} - {{
                                rearsideInfo.value
                                |
                                titlecase }}
                            </li>
                        </div>
                        <div *ngFor="let tiressideInfo of tireSideQv">
                            <li *ngIf="tiressideInfo.value != 'GOOD
                                        CONDITION' && tiressideInfo.value !=
                                        ''">
                                {{ tiressideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                tiressideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <div *ngFor="let ExtraInfo of extraInfoQv">

                            <li *ngIf="ExtraInfo.value">
                                {{ ExtraInfo.key | replaceUnderscorePipe
                                |
                                titlecase }} - {{ ExtraInfo.value |
                                titlecase}}
                            </li>
                        </div>
                    </ul>

                </div>
                <div class="col-6" style="width: 50% !important;
                            float: left;">
                    <ul class="quickview">
                        <div *ngFor="let interiorInfo of
                                    interiorInfoQv">
                            <li *ngIf="interiorInfo.value != 'GOOD
                                        CONDITION' && interiorInfo.value != ''
                                        && interiorInfo.value!= 'YES'">
                                {{ interiorInfo.key |
                                replaceUnderscorePipe |
                                titlecase }} - {{ interiorInfo.value |
                                titlecase}}
                            </li>

                        </div>
                        <div *ngFor="let mechInfo of mechInfoQv">

                            <li *ngIf="mechInfo.value != 'GOOD
                                        CONDITION' && mechInfo.value != '' &&
                                        mechInfo.value!= 'YES'">
                                {{ mechInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                mechInfo.value |
                                titlecase }}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="row" *ngIf="vehicleLoaded" style="margin-top: 16px;">
            <div *ngIf="exInfoAvailable" class="{{infoAvailableClass}} card" style="padding: 0;padding-bottom:
                8px;">
                <div class="product-detail" style="color: #0078D3;">
                    <div class="title-contact" style="color:
                        #0078D3;font-weight: 600;">
                        <i class="fa fa-list"></i>
                        EXTERIOR INFO</div>
                    <hr>
                    <ul style="color: black;padding-left: 36px;">
                        <span style="font-weight: bold;
                            color: #2370b1;" *ngIf="driverAvaliable">DRIVER SIDE</span>
                        <div *ngFor="let driversideInfo of driverside">
                            <li *ngIf="driversideInfo.value">
                                {{ driversideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                driversideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <span style="font-weight: bold;
                            color: #2370b1;" *ngIf="frontAvaliable">FRONT</span>
                        <div *ngFor="let frontsideInfo of frontside">
                            <li *ngIf="frontsideInfo.value">
                                {{ frontsideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                frontsideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <span style="font-weight: bold;
                            color: #2370b1;" *ngIf="passengerAvaliable">PASSENGER
                            SIDE</span>
                        <div *ngFor="let passengersideInfo of passengerside">
                            <li *ngIf="passengersideInfo.value">
                                {{ passengersideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                passengersideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                        <span style="font-weight: bold;
                            color: #2370b1;" *ngIf="rearAvaliable">REAR</span>
                        <div *ngFor="let rearsideInfo of rearside">

                            <li *ngIf="rearsideInfo.value">
                                {{ rearsideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{ rearsideInfo.value
                                |
                                titlecase }}
                            </li>
                        </div>
                        <span style="font-weight: bold;
                            color: #2370b1;" *ngIf="triesAvaliable">TIRES</span>
                        <div *ngFor="let tiressideInfo of tiresside">

                            <li *ngIf="tiressideInfo.value">
                                {{ tiressideInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{
                                tiressideInfo.value |
                                titlecase }}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>

            <div *ngIf="inInfoAvailable" class="{{infoAvailableClass}} card" style="padding: 0;padding-bottom:
                8px;">
                <div class="product-detail" style="color: #0078D3;">
                    <div class="title-contact" style="color:
                        #0078D3;font-weight: 600;">
                        <i class="fa fa-list"></i>
                        INTERIOR INFO</div>
                    <hr>
                    <ul style="color: black;padding-left: 36px;">
                        <div *ngFor="let interiorInfo of leadInteriorInfo">
                            <li *ngIf="interiorInfo.value">
                                {{ interiorInfo.key | replaceUnderscorePipe |
                                titlecase }} - {{ interiorInfo.value |
                                titlecase}}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>

            <div *ngIf="mechInfoAvailable" class="{{infoAvailableClass}} card" style="padding: 0;padding-bottom:
                8px;">
                <div class="product-detail" style="color: #0078D3;">
                    <div class="title-contact" style="color:
                        #0078D3;font-weight: 600;">
                        <i class="fa fa-list"></i>
                        MECHANICAL INFO</div>
                    <hr>
                    <ul style="color: black;padding-left: 36px;">
                        <div *ngFor="let mechInfo of leadMechInfo">

                            <li *ngIf="mechInfo.value">
                                {{ mechInfo.key | titlecase |
                                replaceUnderscorePipe }} - {{ mechInfo.value |
                                titlecase }}
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<app-footer></app-footer>
<app-scroll-up></app-scroll-up>