import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { environment } from "src/environments/environment";
// import { Options } from "ng5-slider";
import { ApiService } from "../services/api.service";
import { MatOption } from "@angular/material/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  makeList: any = [];
  modelList: any = [];
  minYearList: any = [];
  maxYearList: any = [];
  selectedMake: any = null;
  selectedModel: any = null;
  selectedMinYear: any = null;
  selectedMaxYear: any = null;
  selectedInprogress: any = null;
  selectedInventory: any = null;
  selectedAll: any = null;
  checked = false;
  public SettingForm: FormGroup;
  errorMsg: any;
  htmlData: any;
  selectid: any;
  btnclick: any = "save";
  filterdata: any = [];
  settingloadding: boolean = false;
  auto_check: boolean = false;
  auto_carfax: boolean = false;
  odoMin_status: boolean = false;
  odoMaX_staus: boolean = false;
  // allSelected:boolean=false;
  @ViewChild("autoselect") select: MatSelect;
  @ViewChild("allSelected") private allSelected: MatOption;
  // @ViewChild('select') select: MatSelect;
  toppingList: string[] = ["CLEAN", "NOT CLEAN"];
  AutoCheck_value: any = [];
  odometer_value:any=[];
  autoaccident_value: any = [];
  carfax_value: any = [];
  selection: any = [];
  validationchecker: any ;
  minodocheck: any ;
  maxodocheck:any;
  minandmaxrange: any =[];
  constructor(
    private db: AngularFireDatabase,
    private apiService: ApiService,
    private toastr: ToastrService,
    public _fb: FormBuilder,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.btnclick = "save";
    this.getMakeList();
    this.getModelList();
    this.getMinYearList();
    this.getMaxYearList();
    this.getsetting(localStorage.getItem("userId"), 0);
   this. minandmaxrange =  [
      "0",
      "10000",
      "20000",
      "30000",
      "40000",
      "50000",
      "60000",
      "70000",
      "80000",
      "90000",
      "100000",
      "110000",
      "120000",
      "130000",
      "140000",
      "150000",
      "160000",
      "170000",
      "180000",
      "190000",
      "200000"
    ];
    this.SettingForm = this._fb.group({
      yearFrom: [""],
      yearTo: [""],
      make: [""],
      model: [""],
      odoMin: [""],
      odoMaX: [""],
      //odometer:[""],
      priceMin: [""],
      PriceMax: [""],
      inprogress: [""],
      inventry: [""],
      all: [""],
      searchName: [""],
      savedName: [""],
      id: 0,
      empid: [
        localStorage.getItem("userId") ? localStorage.getItem("userId") : "0",
      ],
      autocheck: [""],

      carfax: [""],
      autoaccident: [""],
    });
  }
  
  getMinYearList(selectedMaxyear: any = null) {
    this.minYearList = [];
    const minYr = 1974;
    const maxYr = new Date().getFullYear() + 1;
    // for (let minYear = minYr; minYear <= maxYr; minYear++) {
    //   this.minYearList.push(minYear);
    // }
    for (let maxYear = maxYr; maxYear >= minYr; maxYear--) {
      this.minYearList.push(maxYear);
    }
    console.log(this.minYearList);
  }

  getMaxYearList(selectedMinyear: any = null) {
    this.maxYearList = [];
    let maxYr = new Date().getFullYear() + 1;
    let minYr = 1974;
    console.log("selectedMinyear", selectedMinyear);
    if (selectedMinyear != null && selectedMinyear != "") {
      console.log("selectedMinyear", selectedMinyear);
      maxYr = new Date().getFullYear() + 1;
      minYr = selectedMinyear;
    }

    for (let maxYear = maxYr; maxYear >= minYr; maxYear--) {
      this.maxYearList.push(maxYear);
    }
    // for (let minYear = minYr; minYear <= maxYr; minYear++) {
    //   this.maxYearList.push(minYear);
    // }
    console.log(this.maxYearList);
  }
  onAutoChange(event) {
    console.log(event.target.value);
    if (event.target.value == "ACCIDENT REPORT") {
      this.auto_check = true;
    } else {
      this.auto_check = false;
    }
  }
  oncarfaxChange(event) {
    console.log(event.target.value);
    if (event.target.value == "ACCIDENT REPORT") {
      this.auto_carfax = true;
    } else {
      this.auto_carfax = false;
    }
  }
  getModelList(selectedMake: any = null) {
    console.log(selectedMake);
    if (selectedMake == null || selectedMake == "") {
      this.db
        .list(environment.firebaseRef.modelList)
        .snapshotChanges()
        .subscribe((datasnapshot) => {
          this.modelList = [];
          datasnapshot.forEach((snapshot) => {
            const model = snapshot.payload.toJSON();
            model["id"] = snapshot.key;

            // if (this.selectedStatus == 'in-progress') {
            //   model['count'] = model['count']['Progress'];
            // } else if (this.selectedStatus == 'inventory') {
            //   model['count'] = model['count']['inventory'];
            // } else {
            //   model['count'] = model['count']['All'];
            // }
            model["count"] = model["count"]["All"];

            this.modelList.push(model);
          });
          console.log(this.modelList);
        });
    } else {
      const modelRef = environment.firebaseRef.modelList;
      this.db
        .list(modelRef, (modelRef) =>
          modelRef.orderByChild("make").equalTo(selectedMake)
        )
        .snapshotChanges()
        .subscribe((datasnapshot) => {
          this.modelList = [];
          datasnapshot.forEach((snapshot) => {
            const model = snapshot.payload.toJSON();
            model["id"] = snapshot.key;

            // if (this.selectedStatus == 'in-progress') {
            //   model['count'] = model['count']['Progress'];
            // } else if (this.selectedStatus == 'inventory') {
            //   model['count'] = model['count']['inventory'];
            // } else {
            //   model['count'] = model['count']['All'];
            // }
            model["count"] = model["count"]["All"];
            this.modelList.push(model);
          });
          console.log(this.modelList);
        });
    }
  }

  getMakeList() {
    this.db
      .list(environment.firebaseRef.makeList)
      .snapshotChanges()
      .subscribe((datasnapshot) => {
        this.makeList = [];
        datasnapshot.forEach((snapshot) => {
          const make = snapshot.payload.toJSON();
          make["id"] = snapshot.key;

          // if (this.selectedStatus == 'in-progress') {
          //   make['count'] = make['count']['Progress'];
          // } else if (this.selectedStatus == 'inventory') {
          //   make['count'] = make['count']['inventory'];
          // } else {
          //   make['count'] = make['count']['All'];
          // }
          make["count"] = make["count"]["All"];
          this.makeList.push(make);
        });
        console.log(this.makeList);
      });
  }
  onMakeChange(event: any) {
    // localStorage.setItem("selectedMake", event.target.value);
    this.selectedModel = null;
    console.log("onMakeChange", event.target.value);
    const selectedMake = event.target.value;
    if (selectedMake) {
      this.selectedMake = selectedMake;
      this.getModelList(selectedMake);
    } else {
      this.selectedMake = null;
      this.getModelList();
    }
    // this.onFilterVehicles();
  }

  onModelChange(event: any) {
    // localStorage.setItem("selectedModel", event.target.value);
    console.log("onModelChange", event.target.value);
    const selectedModel = event.target.value;
    if (selectedModel) {
      this.selectedModel = selectedModel;
    } else {
      this.selectedModel = null;
    }
    //this.onFilterVehicles();
  }

  onMinYearChange(event: any) {
    // localStorage.setItem("selectedMinYear", event.target.value);
    console.log("onMinYearChange", event.target.value);
    const selectedMinYear = event.target.value;
    if (selectedMinYear) {
      this.selectedMinYear = selectedMinYear;
      this.getMaxYearList(selectedMinYear);
    } else {
      this.selectedMinYear = null;
      this.getMaxYearList();
    }
    //this.onFilterVehicles();
  }
  onMinOdoChange(event: any)
  {
    console.log("onMinOdoChange", event);
    this.minodocheck = event;
  }
  onMaxOdoChange(event: any)
  {
    console.log("onMaxOdoChange", event);
    this.maxodocheck = event;
    if(this.maxodocheck < this.minodocheck)
    {
      this.validationchecker= true;
    }
    else{
      this.validationchecker =false;
    }
  }
  onMaxYearChange(event: any) {
    // localStorage.setItem("selectedMaxYear", event.target.value);
    console.log("onMaxYearChange", event.target.value);
    const selectedMaxYear = event.target.value;
    if (selectedMaxYear) {
      this.selectedMaxYear = selectedMaxYear;
    } else {
      this.selectedMaxYear = null;
    }
    //  this.onFilterVehicles();
  }
  onCheckInprogress(event: any) {
    // localStorage.setItem("selectedInprogress", event.target.value);
    const selected_Inprogress = event.target.value;

    if (event.target.checked == true) {
      this.selectedInprogress = selected_Inprogress;
    } else {
      this.selectedInprogress = null;
    }
  }
  onCheckInventry(event: any) {
    // localStorage.setItem("selectedInventory", event.target.value);
    const selected_Inventory = event.target.value;

    if (event.target.checked == true) {
      this.selectedInventory = selected_Inventory;
    } else {
      this.selectedInventory = null;
    }
  }
  onCheckAll(event: any) {
    // localStorage.setItem("selectedAll", event.target.value);
    const selected_All = event.target.value;
    console.log(event);
    if (event.target.checked == true) {
      this.SettingForm.get("inprogress").setValue(0);
      this.SettingForm.get("inventry").setValue(0);
      this.selectedInventory = null;
      this.selectedInprogress = null;
      this.selectedAll = selected_All;
    } else {
      this.selectedAll = null;
    }
  }

  removeOption(option: string) {
    switch (option) {
      case "make":
        this.selectedMake = null;

        break;
      case "model":
        this.selectedModel = null;

        break;
      case "minYear":
        this.selectedMinYear = null;

        break;
      case "maxYear":
        this.selectedMaxYear = null;

        break;
      case "Inprogress":
        this.selectedInprogress = null;
        this.SettingForm.get("inprogress").setValue(0);

        break;
      case "Inventory":
        this.selectedInventory = null;

        this.SettingForm.get("inventry").setValue(0);

        break;
      case "All":
        this.selectedAll = null;
        this.SettingForm.get("all").setValue(0);

        break;
    }
  }

  // odoMinValue: number = 0;
  // odoMaxValue: number = 0;
  // options: Options = {
  //   floor: 0,
  //   ceil: 200000,
  // };

  getsetting(userid, id) {
    this.apiService.getSetting(userid, id).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.filterdata = resultData;
      },
      (error) => {}
    );
  }
  removesetting(id) {
    this.apiService.removeSetting(localStorage.getItem("userId"), id).subscribe(
      (resultData: any) => {
        if (resultData == 1) {
          if(localStorage.getItem("selectid") == id)
          {
            localStorage.removeItem("selectid");
          }
          
          this.toastr.success("Deleted successfully", null, {
            timeOut: 3000,
          });
          this.resetform();
        }
      },
      (error) => {}
    );
  }

  getfilterdata(id) {
    this.selectid = id;
    this.btnclick = "Update";
    this.selectedMake = null;
    this.selectedModel = null;
    this.selectedMinYear = null;
    this.selectedMaxYear = null;
    this.selectedInprogress = null;
    this.selectedInventory = null;
    this.selectedAll = null;
    this.carfax_value = [];
    this.AutoCheck_value = [];
    this.odometer_value = [];
    this.autoaccident_value = [];
    this.apiService.getSetting(localStorage.getItem("userId"), id).subscribe(
      (resultData: any) => {
        console.log(resultData);

        // this.SettingForm.reset;
        this.SettingForm.get("id").setValue(resultData[0].id);
        // this.getModelList(resultData[0].make != "" ? resultData[0].make : null);
        // this.odoMinValue = resultData[0].odoMin;
        // this.odoMaxValue = resultData[0].odoMaX;
        this.SettingForm.get("inprogress").setValue(
          resultData[0].inprogress == "1" ? 1 : 0
        );
        this.SettingForm.get("inventry").setValue(
          resultData[0].inventry == "1" ? 1 : 0
        );
        this.SettingForm.get("all").setValue(resultData[0].ALL == "1" ? 1 : 0);
        this.SettingForm.get("searchName").setValue(resultData[0].searchName);
        this.selectedMinYear =
          resultData[0].yearFrom != "" ? resultData[0].yearFrom : null;
        this.selectedMake =
          resultData[0].make != "" ? resultData[0].make : null;

        this.selectedModel =
          resultData[0].model != "" ? resultData[0].model : null;

        this.selectedMaxYear =
          resultData[0].yearTo != "" ? resultData[0].yearTo : null;

        this.selectedInprogress =
          resultData[0].inprogress == "1" ? "In-progress" : null;
        this.selectedInventory =
          resultData[0].inventry == "1" ? "Inventory" : null;
        this.selectedAll = resultData[0].ALL == "1" ? "All" : null;
        console.log(this.selectedInprogress, this.selectedInventory);

        this.SettingForm.get("yearFrom").setValue(resultData[0].yearFrom);
        this.SettingForm.get("yearTo").setValue(resultData[0].yearTo);
        this.SettingForm.get("make").setValue(resultData[0].make);
        this.SettingForm.get("model").setValue(resultData[0].model);
        this.SettingForm.get("odoMin").setValue(resultData[0].odoMin);
        this.SettingForm.get("odoMaX").setValue(resultData[0].odoMaX);

        // resultData[0].odometer.forEach((value, index) => {
        //   console.log(value);
        //   this.odometer_value.push(value);
        // });
        console.log(this.odometer_value);
      //  this.SettingForm.controls["odometer"].setValue(this.odometer_value);
        // if(resultData[0].AutoCheck)
        resultData[0].AutoCheck.forEach((value, index) => {
          console.log(value);
          this.AutoCheck_value.push(value);
        });
        console.log(this.AutoCheck_value);
        this.SettingForm.controls["autocheck"].setValue(this.AutoCheck_value);
        resultData[0].CarFax.forEach((value, index) => {
          this.carfax_value.push(value);
        });
        this.SettingForm.get("carfax").setValue(this.carfax_value);
        // resultData[0].Accidents.forEach((value, index) => {
        //   this.autoaccident_value.push(value);
        // });
        // this.SettingForm.controls["autoaccident"].setValue(
        //   this.autoaccident_value
        // );
      },
      (error) => {}
    );
  }
  public compareWith(object1: any, object2: any) {
    return object1.value === object2.value;
  }
  savesetting() {
    console.log(this.SettingForm.value.auto_accident);
    let data = {
      yearFrom: this.SettingForm.value.yearFrom,
      yearTo: this.SettingForm.value.yearTo,
      make: this.SettingForm.value.make,
      model: this.SettingForm.value.model,
      odoMin: parseInt(this.SettingForm.value.odoMin),
      odoMaX: parseInt(this.SettingForm.value.odoMaX),
      odometer: [],
      inprogress: this.SettingForm.value.inprogress ? "1" : "0",
      inventry: this.SettingForm.value.inventry ? "1" : "0",
      all: this.SettingForm.value.all ? "1" : "0",
      searchName: this.SettingForm.value.searchName
        ? this.SettingForm.value.searchName.charAt(0).toUpperCase() +
          this.SettingForm.value.searchName.slice(1)
        : "",
      savedName: this.SettingForm.value.searchName
        ? this.SettingForm.value.searchName.charAt(0).toUpperCase() +
          this.SettingForm.value.searchName.slice(1)
        : "",
      id: this.selectid != "" && this.selectid != null ? this.selectid : 0,
      empid: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : "0",
      AutoCheck:
        this.SettingForm.value.autocheck != ""
          ? this.SettingForm.value.autocheck
          : [],
      CarFax:
        this.SettingForm.value.carfax != ""
          ? this.SettingForm.value.carfax
          : [],
      Accidents:
        this.SettingForm.value.autoaccident != ""
          ? this.SettingForm.value.autoaccident
          : null,
    };
    // if (this.SettingForm.value.odoMin != "") {
    //   if (this.SettingForm.value.odoMin = 0) {
    //     this.odoMin_status = true;
    //   }
    // }
    if (
      this.SettingForm.value.odoMaX != "" && this.SettingForm.value.odoMin != ""
    ) {
      console.log("ttttt");
      // if (this.SettingForm.value.odoMin = this.SettingForm.value.odoMaX) {
      //   this.odoMaX_staus = true;
      // }
      if (this.SettingForm.value.odoMin >= this.SettingForm.value.odoMaX) {
        this.odoMaX_staus = true;
      }
      else
      {
        this.odoMaX_staus = false;
      }
    }
    if (this.SettingForm.invalid || this.odoMaX_staus == true) {
      return;
    }
  
    console.log(this.SettingForm.invalid);
    if (
      this.SettingForm.value.yearFrom != "" ||
      this.SettingForm.value.yearTo != "" ||
      this.SettingForm.value.make != "" ||
      this.SettingForm.value.model != "" ||
      this.SettingForm.value.odoMin != "" ||
      this.SettingForm.value.odoMaX != "" ||
      this.SettingForm.value.inprogress != 0 ||
      this.SettingForm.value.inventry != 0 ||
      this.SettingForm.value.all != 0 ||
      this.SettingForm.value.autocheck != "" ||
      this.SettingForm.value.carfax != ""
    ) {
      // if (this.SettingForm.value.odoMin != "") {
      //   if (this.SettingForm.value.odoMin >= 0) {
      //     this.odoMin_status = true;
      //   }
      // }
      // if (
      //   this.SettingForm.value.odoMaX != "" &&
      //   this.SettingForm.value.odoMin != ""
      // ) {
      //   if (this.SettingForm.value.odoMin >= this.SettingForm.value.odoMaX) {
      //     this.odoMaX_staus = true;
      //   }
      // }
      console.log("Testing validation");
   
      if (this.SettingForm.value.searchName == "") {
        console.log("Testing validation 1233");
        this.SettingForm.controls["searchName"].setValidators([
          Validators.required,
        ]);
        this.SettingForm.controls["searchName"].updateValueAndValidity();
        console.log(this.SettingForm);
      } 
      // else if (this.SettingForm.value.odoMin != "") {
      //   if (this.SettingForm.value.odoMin >= 0) {
      //     this.odoMin_status = true;
      //   }
      // } else if (
      //   this.SettingForm.value.odoMaX != "" &&
      //   this.SettingForm.value.odoMin != ""
      // ) {
      //   if (this.SettingForm.value.odoMin >= this.SettingForm.value.odoMaX) {
      //     this.odoMaX_staus = true;
      //   }
      // } 
      else {
        this.settingloadding = true;
        console.log(data);
        this.apiService.saveSetting(data).subscribe(
          (resultData: any) => {
            console.log("ResultData: ", resultData);
            this.settingloadding = false;

            if (resultData.status == "0") {
              this.toastr.error(resultData.msg, null, {
                timeOut: 3000,
              });
            } else if (resultData.status == "1") {
              this.toastr.success(resultData.msg, null, {
                timeOut: 3000,
              });
              this.resetform();
            }
          },
          (error) => {
            this.settingloadding = false;
            this.errorMsg = "Something went wrong. Try again!";

            console.log(error);
          }
        );
      }
    } else {
      this.toastr.error("Please select one filter", null, {
        timeOut: 3000,
      });
    }

    console.log(this.SettingForm.value.searchName);
  }
  navToHome() {
    console.log("Testing back");
    localStorage.setItem("backtohome", "true");
    this.router.navigateByUrl("/inventory");
  }
  resetform() {
    // this.SettingForm.reset();
    this.selectedMake = null;
    this.selectedModel = null;
    this.selectedMinYear = null;
    this.selectedMaxYear = null;
    this.selectedInprogress = null;
    this.selectedInventory = null;
    this.selectedAll = null;
    this.selectid = null;
    // this.odoMinValue = 0;
    // this.odoMaxValue = 0;
    this.SettingForm.get("yearFrom").setValue("");
    this.SettingForm.get("yearTo").setValue("");
    this.SettingForm.get("inprogress").setValue(0);
    this.SettingForm.get("inventry").setValue(0);
    this.SettingForm.get("all").setValue(0);
    this.SettingForm.get("searchName").setValue("");
    this.SettingForm.get("odoMin").setValue("");
    this.SettingForm.get("odoMaX").setValue("");
    this.SettingForm.get("autocheck").setValue("");
    this.SettingForm.get("carfax").setValue("", "");
    // this.SettingForm.get("carfax_accident").setValue("");
    this.SettingForm.get("autoaccident").setValue("", "");
    this.auto_check = false;
    this.auto_carfax = false;
    this.getsetting(localStorage.getItem("userId"), 0);
    this.getMinYearList();
    this.getMaxYearList();
    this.getMakeList();
    this.getModelList();
    this.btnclick = "Save";
    console.log("Select Max year", this.selectedMaxYear);
  }
}
