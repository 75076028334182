<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'CONTACT'"></app-header>
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
    <!-- End Google Tag Manager -->
    
    <!-- Google Tag Manager (noscript) -->
    <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
    <!-- End Google Tag Manager (noscript) -->
<div class="main_content">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">

                <div class="colo-md-12 col-12 custom-loader"
                    *ngIf="contactUsClicked">
                    <img src="../../assets/images/loader.gif" height="300"
                        alt="">
                </div>

                <div class="col-xl-8 col-md-10" *ngIf="!contactUsClicked">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div class="heading_s1">
                                <h3>Contact Us</h3>
                                <p class="error-msg" style="margin-top: 12px;"
                                    *ngIf="errorMsg">{{errorMsg}}</p>
                                <p class="success-msg" style="margin-top: 12px;"
                                    *ngIf="successMsg">{{successMsg}}</p>
                            </div>
                            <form method="post" [formGroup]="contactForm"
                                (ngSubmit)="contactUs()">
                                <div class="ip-ct">
                                    <div class="form-group">
                                        <input type="text"
                                            (change)="clearMsg();" required=""
                                            class="form-control"
                                            formControlName="firstName"
                                            placeholder="First Name">
                                        <div *ngIf="contactSubmitted &&
                                            contactForm.controls.firstName.invalid">
                                            <span class="error-msg"
                                                *ngIf="contactForm.controls.firstName.errors.required">First
                                                Name is required</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            (change)="clearMsg();" required=""
                                            class="form-control"
                                            formControlName="lastName"
                                            placeholder="Last Name">
                                        <div *ngIf="contactSubmitted &&
                                            contactForm.controls.lastName.invalid">
                                            <span class="error-msg"
                                                *ngIf="contactForm.controls.lastName.errors.required">Last
                                                Name is required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ip-ct">
                                    <div class="form-group">
                                        <input type="email"
                                            (change)="clearMsg();" required=""
                                            class="form-control"
                                            formControlName="email"
                                            [pattern]="emailPattern"
                                            placeholder="Email Address">
                                        <div *ngIf="contactSubmitted &&
                                            contactForm.controls.email.invalid">
                                            <span class="error-msg"
                                                *ngIf="contactForm.controls.email.errors.required">Email
                                                address is required</span>
                                            <span class="error-msg"
                                                *ngIf="contactForm.controls.email.errors.pattern">Enter
                                                valid email address</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="number"
                                            (change)="clearMsg();" required=""
                                            class="form-control"
                                            formControlName="phone"
                                            placeholder="Phone Number">
                                        <div *ngIf="contactSubmitted &&
                                            contactForm.controls.phone.invalid">
                                            <span class="error-msg"
                                                *ngIf="contactForm.controls.phone.errors.required">Phone
                                                Number is required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" (change)="clearMsg();"
                                        required="" class="form-control"
                                        formControlName="subject"
                                        placeholder="Enter your subject">
                                    <div *ngIf="contactSubmitted &&
                                        contactForm.controls.subject.invalid">
                                        <span class="error-msg"
                                            *ngIf="contactForm.controls.subject.errors.required">Subject
                                            is required</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <textarea type="text" (change)="clearMsg();"
                                        required="" class="form-control"
                                        formControlName="message"
                                        placeholder="Enter your message"></textarea>
                                    <div *ngIf="contactSubmitted &&
                                        contactForm.controls.message.invalid">
                                        <span class="error-msg"
                                            *ngIf="contactForm.controls.message.errors.required">Message
                                            is required</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn
                                        btn-fill-out btn-block" name="Contact">Send
                                        Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>