<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'REGISTER'"></app-header>

<div class="main_content">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">

                <div class="colo-md-12 col-12 custom-loader" *ngIf="registeringDealer">
                    <img src="../../assets/images/loader.gif" height="300" alt="">
                </div>

                <div class="col-xl-8 col-md-10" *ngIf="!registeringDealer">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div *ngIf="shwRegCont" class="registration_content">
                            <div class="heading_s1">
                                <h3>New Dealer Registration</h3>
                                <p class="error-msg" style="margin-top: 12px;" *ngIf="errorMsg">{{errorMsg}}</p>
                            </div>
                            <form method="post" [formGroup]="registerForm" (ngSubmit)="registerDealer()">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="dealerName" placeholder="Dealer Name">
                                            <div *ngIf="registerSubmitted && registerForm.controls.dealerName.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.dealerName.errors.required">Dealer Name is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="dealerAddress" placeholder="Dealer Address">
                                            <div *ngIf="registerSubmitted && registerForm.controls.dealerAddress.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.dealerAddress.errors.required">Dealer Address is required</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="dealerEmail" placeholder="Dealer Email" (focusout)="onDealeremail($event)">
                                            <div *ngIf="registerSubmitted && registerForm.controls.dealerEmail.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.dealerEmail.errors.required">Dealer Email is required</span>
                                                <span class="error-msg" *ngIf="registerForm.controls.dealerEmail.errors.pattern">Enter valid email address</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="city" placeholder="City">
                                            <div *ngIf="registerSubmitted && registerForm.controls.city.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.city.errors.required">City is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="state" placeholder="State">
                                            <div *ngIf="registerSubmitted && registerForm.controls.state.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.state.errors.required">State is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="zip" placeholder="Zip Code">
                                            <div *ngIf="registerSubmitted && registerForm.controls.zip.invalid">
                                                <span class="error-msg" *ngIf="registerForm.controls.zip.errors.required">Zip is required</span>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="titledept">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="det-titles" style="margin-left: 0px;">Title Department</h6>
                                        </div>
                                    </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="titleDptContPerson" placeholder="Contact Person">
                                                    <div *ngIf="registerSubmitted && registerForm.controls.titleDptContPerson.invalid">
                                                        <span class="error-msg" *ngIf="registerForm.controls.titleDptContPerson.errors.required">Contact Person is required</span>
                                                        <span class="error-msg" *ngIf="registerForm.controls.titleDptContPerson.errors.pattern">Contact Person is Invalid</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="titleDptDirectContactPhNo" placeholder="Direct Contact No.">
                                                    <div *ngIf="registerSubmitted && registerForm.controls.titleDptDirectContactPhNo.invalid">
                                                        <span class="error-msg" *ngIf="registerForm.controls.titleDptDirectContactPhNo.errors.required">Direct Contact Ph No is required</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="email" (change)="clearMsg();" required=""  class="form-control shwplhldr" formControlName="titleDptEmailAddress" [pattern]="emailPattern" placeholder="Email Address" (focusout)="onTitleemail($event)">
                                                    <div *ngIf="registerSubmitted && registerForm.controls.titleDptEmailAddress.invalid">
                                                        <span class="error-msg" *ngIf="registerForm.controls.titleDptEmailAddress.errors.required">Email Address is required</span>
                                                        <span class="error-msg" *ngIf="registerForm.controls.titleDptEmailAddress.errors.pattern">Enter valid email address</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-check sameasdlrmail">
                                                    <input class="form-check-input" type="checkbox" value=""  (change)="fieldsChange($event)" id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                      Same as dealer email
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="emailsmandatory" *ngIf="showBOSoption">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="det-titles" style="margin-left: 0px;">Email to send Bill of Sale</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6" *ngIf="dealeremailBind !=''">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""  (change)="emailoption($event)" id="flexCheckDefault" [(checked)]="isCheckedbos" >
                                                <label class="form-check-label" for="flexCheckDefault">
                                                  {{dealeremailBind}}
                                                </label>
                                              </div>
                                        </div>
                                        <div class="col-md-6" *ngIf="titleemailBind != ''">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" (change)="emailoption_bos($event)" id="flexCheckDefault" [(checked)]="isChecked_bos" >
                                                <label class="form-check-label" for="flexCheckDefault">
                                                  {{titleemailBind}}
                                                </label>
                                              </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    
                                    <div class="col-md-12">
                                        <h6 class="det-titles" style="margin-left: 0px;">Add additional email address</h6>
                                        <div class="form-group emailBOS-chip-list">
                                            <mat-chip-list #chipList aria-label="">
                                                <input class="shwplhldr emailslistvw" placeholder="Emails to send BOS"
                                                        [matChipInputFor]="chipList"
                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        [matChipInputAddOnBlur]="addOnBlur"
                                                        (matChipInputTokenEnd)="add($event)" autocomplete="doNotAutoComplete">
                                                <span class="col-md-12 errorBOSemails" *ngIf="emailValidBOS">Enter valid email ID</span>
                                            </mat-chip-list>
                                            <div class="row">
                                                <div class="col-md-12 alignchip">
                                                    <mat-chip *ngFor="let BOSmail of BOSemails"
                                                        [removable]="removable" (removed)="remove(BOSmail)">
                                                        {{BOSmail.name}}
                                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                     </mat-chip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="other-det">
                                    <div class="row">
                                        <div class="col-md-12 autobuywlemail">
                                            <h6 class="det-titles">AUTOBUY will email.</h6>
                                            <ul class="list-details">
                                                <li>Bill of Sale</li>
                                                <li>Copy of Title</li>
                                                <li>Reassignment if needed</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 plcydet">
                                            <h6 class="det-titles">Policies</h6>
                                            <ul class="list-details">
                                                <li>Upon confirmation that the Title is correct</li>
                                                <li>We will send all Titles via Fedex and supply a tracking number for next day delivery.</li>
                                                <li>You will send a check via your courier and supply AUTOBUY with tracking number for next day delivery.</li>
                                                <li>Exchange of Title for check 48hrs after notice of Title ready.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-12 dlrdocneed">
                                            <h6 class="det-titles">Dealer documents needed:</h6>
                                            <ul class="list-details">
                                                <li>Dealer License</li>
                                                <li>Sales Tax Certificate</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row main-det">
                                        <div class="col-md-12">
                                            <h6 class="det-titles">Our Title Dept Contact Person: JANINE</h6>
                                        </div>
                                        <div class="col-md-12">
                                            <h6 class="det-titles">Email Address: <a href="mailto:janine@wepaythemax.com" style="text-decoration: underline;">janine@wepaythemax.com</a></h6>
                                        </div>
                                        <div class="col-md-12">
                                            <h6 class="det-titles phnoo">Phone Number: <a href="javascript:void(0);">(561) 797-2400 Ext 218</a></h6>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-fill-out btn-block btn-sze" name="Register">Register</button>
                                    </div>
                            </form>
                            <div class="form-note text-center alreadyAcc">Already Have an Account? <a [routerLink]="['/login']" routerLinkActive="router-link-active" >Login</a></div>
                        </div>
                        <div *ngIf="showThanksContnt" class="thankyou_content">
                            <div class="row tick-symbol">
                                <img class="img-tick" src="../../assets/images/reg_verification.png">
                            </div>
                            <div class="row">
                                <div class="col-md-12 reg-succ">
                                    <h3>Registered Successfully</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 cont-team">
                                    <h6>Our team will contact you soon</h6>
                                    <h6>Thank you</h6>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>