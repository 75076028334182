<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
    <!-- End Google Tag Manager -->
    
    <!-- Google Tag Manager (noscript) -->
    <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
    <!-- End Google Tag Manager (noscript) -->
<div class="product">
    <span class="pr_flash ribbon black">{{vehicle['location']}}</span>
    <a routerLink="/detail/{{vehicle['leadId']}}"><div class="product_img"
            [ngStyle]="{'background-image': 'url(' + vehicle['previewImage'] +
            ')'}"></div></a>
    <div class="product_info">
        <a routerLink="/detail/{{vehicle['leadId']}}">
            
            <div class="time-ago-ct" *ngIf="vehicle['status'] == 3">
                <!-- <span class="time-ago" *ngIf="!vehicle['bought']">
                    <span class="pricetag">Inprogress from last {{ vehicle['timeAgo'] | relativeTime  }}</span>
                </span>
                <span class="time-ago" *ngIf="vehicle['bought']">&nbsp;</span> -->
                <svg version="1.1" id="elp-badge" class="badge"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="10 10 289.1 289.1" enable-background="new 0 0 189.1
                    189.1" style="background-color:#00BB66"	
                    xml:space="preserve">
                    <!--<circle class="circle" fill="#28BDEE" cx="144.5" cy="144.5" r="144.5"/>-->
                    <path id="SVGID_x5F_2_x5F_" fill="none" 
                        d="M31.1,129c10.4-62.1,69.2-104,131.4-93.6s104,69.2,93.6,131.4
                        c-10.4,62.1-69.2,104-131.4,93.6C62.5,249.9,20.6,191.1,31.1,129z"
                        />
                        
                        <text class="badge-percentage"  transform="matrix(0.9962
                            0.1256 -0.1056 0.9862 148 170)" fill="white"
                            font-weight="bold" font-size="55" style="color: white;"
                            text-anchor="middle">{{ "SOLD" }}</text>
                        <path id="SVGID_x5F_1_x5F_" fill="none"
                            d="M263.9,172.8c11-65.5-33.2-127.6-98.7-138.6S37.5,67.4,26.5,133s33.2,127.6,98.7,138.6
                            C190.8,282.6,252.9,238.4,263.9,172.8z" />
                            
                        </svg>
                    </div>
            <div class="time-ago-ct" *ngIf="vehicle['status'] == 1">
                <!-- <span class="time-ago" *ngIf="!vehicle['bought']">
                    <span class="pricetag">Inprogress from last {{ vehicle['timeAgo'] | relativeTime  }}</span>
                </span>
                <span class="time-ago" *ngIf="vehicle['bought']">&nbsp;</span> -->
                <svg version="1.1" id="elp-badge" class="badge"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="10 10 289.1 289.1" enable-background="new 0 0 189.1
                    189.1"
                    xml:space="preserve">
                    <!--<circle class="circle" fill="#28BDEE" cx="144.5" cy="144.5" r="144.5"/>-->
                    <path id="SVGID_x5F_2_x5F_" fill="none"
                        d="M31.1,129c10.4-62.1,69.2-104,131.4-93.6s104,69.2,93.6,131.4
                        c-10.4,62.1-69.2,104-131.4,93.6C62.5,249.9,20.6,191.1,31.1,129z"
                        />
                        <text text-anchor="middle">
                            <textPath xlink:href="#SVGID_x5F_2_x5F_"
                                startOffset="185">
                                <tspan class="badge-header" fill="#7b7a7a"
                                    font-size="31">PENDING</tspan>
                            </textPath>
                        </text>
                        <text class="badge-percentage" transform="matrix(0.9962
                            0.1256 -0.1056 0.9862 148 120)" fill="#000000"
                            font-weight="bold" font-size="55"
                            text-anchor="middle">{{ vehicle['timeAgo'] |
                            relativeTime }}</text>
                        <text class="badge-percentage" transform="matrix(0.9962
                            0.1256 -0.1056 0.9862 148 180)" fill="#000000"
                            font-weight="bold" font-size="55"
                            text-anchor="middle">{{ vehicle['timeAgo'] |
                            relativeTimeDay }}</text>
                        <path id="SVGID_x5F_1_x5F_" fill="none"
                            d="M263.9,172.8c11-65.5-33.2-127.6-98.7-138.6S37.5,67.4,26.5,133s33.2,127.6,98.7,138.6
                            C190.8,282.6,252.9,238.4,263.9,172.8z" />
                            <text text-anchor="middle">
                                <textPath xlink:href="#SVGID_x5F_1_x5F_"
                                    startOffset="575">
                                    <tspan class="badge-footer" fill="#7b7a7a"
                                        font-size="31">NEGOTIATION</tspan>
                                </textPath>
                            </text>
                        </svg>
                    </div>
                    <div class="product-name-ct">
                        <div class="product-year">{{vehicle['year']}}</div>
                        <div class="product-make">{{vehicle['make']}}</div>
                        <!-- <div class="product-price vehicle-price">{{vehicle['bought'] ? "INVENTORY" : "IN-PROGRESS"}}</div> -->
                        <div class="product-price vehicle-price showroom">
                            <!-- <i class='fas fa-car'></i> -->
                            <span *ngIf="selectedStatus != 'sold' &&
                            vehicle['status'] == 2">
                                <i class='fas fa-car' style="color:
                                    #0078D3;font-size:
                                    20px;margin-right: 8px;"></i>
                            </span>

                            <!-- <span *ngIf="selectedStatus == 'sold'">
                                <i class='fas fa-dollar-sign' style="color:
                                    #0078D3;font-size:
                                    20px;margin-right: 8px;"></i>
                            </span> -->


                            <!-- <span class="status-container" *ngIf="!vehicle['bought']">
                        <i class="fas fa-hourglass-half fa-spin" style="color:
                            #0078D3;font-size: 20px;margin-right: 8px;"></i>
                    </span> -->
                        </div>
                    </div>
                </a>
                <a routerLink="/detail/{{vehicle['leadId']}}">
                    <div class="product-model-ct">
                        <div class="product-model">{{vehicle['model']}}</div>
                    </div>
                </a>
                <div class="product_price product_mileage_container">
                    <span class="mileage">
                        <i class="fas fa-tachometer-alt" style="margin-right:
                            8px;color:
                            #0078D3;"></i>{{vehicle['mileage'] |
                        insertCommaPipe}} mi
                    </span>
                </div>
                <div>
                    <button routerLink="/detail/{{vehicle['leadId']}}"
                        id="{{vehicle['leadId']}}" class="btn
                        btn-outline-primary
                        btn-block learn-more-btn" style="font-weight:
                        600;padding-top:
                        8px;padding-bottom: 8px;">LEARN MORE</button>
                </div>
            </div>
        </div>