<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'INVENTORY'"></app-header>
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
   <!-- End Google Tag Manager -->
   
   <!-- Google Tag Manager (noscript) -->
   <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
   height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
   <!-- End Google Tag Manager (noscript) -->
<div class="section">
   <div class="container">
      <div class="row">
         <div class="col-md-4"></div>
         <div class="col-md-4"></div>
         <div class="col-md-4">
            <form (submit)="searchLead()">
               <input
               type="text"
               placeholder="Reference ID/VIN"
               id="srchname"
               class="srch-name"
               name="srchname"
               [(ngModel)]="searchModel">
            </form>
            <div class="srch-icon" (click)="searchLead()">
               <i class="fa fa-search"
                  aria-hidden="true"
                  style="cursor:pointer;color: #0078d3;">
               </i>
            </div>
         </div>
      </div>
      <div class="row" style="margin-top: 32px;">
         <div class="col-md-12" style="margin-bottom: 16px;">
            <span
               style="float: right;font-size: 14px;"
               *ngIf="(filteredCarCount != null)">
               {{filteredCarCount}}
               {{filteredCarCount == 1 ? 'car' : 'cars'}} found.
            </span>

            <div class="row">
               <div class="col-md-2">
                  <h2 class="show-dsplying">Displaying</h2>
               </div>
               <div class="col-md-10">
                  <div class="btn-group set-btns"
                     role="group">
                     <div class="row">
                        <div class="col-md-3">
                           <button
                           type="button"
                           class="btn btn-outline-primary blue-bnt all-btn"
                           [ngClass]="[(selectedStatus == 'all') ||
                           (selectedStatus == 'myfilter' && !loggedIn) ?
                           'status-active' : '']"
                           (click)="filterStatus('all');">
                           All
                           </button>
                           </div>
                           <div class="col-md-3">
                              <button type="button" class="btn btn-outline-primary blue-bnt"
                              [ngClass]="[(selectedStatus == 'in-progress') ?
                              'status-active' : '']"
                              (click)="filterStatus('in-progress');">
                              <i class="fas fa-hourglass-half"></i>
                              In-Progress
                              </button>
                           </div>
                           <div class="col-md-3">
                              <button type="button" class="btn
                              btn-outline-primary blue-bnt"
                              [ngClass]="[(selectedStatus == 'inventory') ?
                              'status-active' : '']"
                              (click)="filterStatus('inventory');"
                              style="padding-top: 8px;">
                              <i class='fas fa-car'></i> Inventory
                              </button>
                           </div>
                           <div class="col-md-3">
                              <button type="button" class="btn
                              btn-outline-primary blue-bnt"
                              [ngClass]="[selectedStatus == 'sold' ?
                              'status-active' : '']"
                              (click)="filterStatus('sold');">
                              <i class='fas fa-dollar-sign'></i> Sold
                              </button>
                           </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row filter-options-ct" style="margin-top: 25px;"
               *ngIf="loggedIn ">
               <h4 style="margin-left: 18px;color: black;margin-right:
                  8px;margin-top:16px;"
                  *ngIf="myFilters.length> 0">
                  My Saved Filters
               </h4>
               <span style="margin-left: 18px;color: black;"
                  *ngIf="myFilters.length == 0">No Saved Search Found</span>
               <mat-chip-list *ngIf="myFilters.length> 0"
                  style="margin-left:16px;margin-top:14px;">
                  <mat-chip
                  *ngFor="let myFilter of myFilters"
                  [ngClass]="[myFilter.id == selectedFilterId ?
                  'status-filter' :
                  '']">
                  <span  (click)="getMyFilterData(myFilter.id)"> {{myFilter.savedName}}</span>
                  <i class="fas fa-times-circle close-icon saved-close"  [ngClass]="[myFilter.id == selectedFilterId ?
                  'show_chip' :'hide_chip']"
                  (click)="removefilter();"></i>
                  </mat-chip>
               </mat-chip-list>
               <!-- <div class="col-md-12" style="margin-left: 18px;
                  color: black;
                  margin-top: 16px;
                  margin-right: 8px;display: contents;"
                  *ngIf="myFilters.length> 0">
                  
                  
                  <span *ngFor="let myFilter of myFilters">
                      <div class="filter-option-save"
                          [ngClass]="[myFilter.id == selectedFilterId ?
                          'status-filter' :
                          '']">
                          <div class="filter-options filterValue"
                              (click)="getMyFilterData(myFilter.id)"
                              style="float:
                              left;font-weight: bold;">
                              {{myFilter.savedName}}</div>
                      </div>
                  </span>
                  </div> -->
            </div>
            <form class="filter-form">
               <div class="make-container">
                  <label>Make</label>
                  <select class="form-control"
                     (change)="onMakeChange($event);">
                     <option value="">Any</option>
                     <option value="{{make['name']}}"
                     *ngFor="let make of makeList"
                     [selected]="make['name'] == selectedMake">
                     {{make['name']}} ({{make['count']}})
                     </option>
                  </select>
               </div>
               <div class="make-container">
                  <label>Model</label>
                  <select class="form-control"
                     (change)="onModelChange($event);">
                     <option value="">Any</option>
                     <option value="{{model['name']}}" *ngFor="let model
                     of modelList" [selected]="model['name'] ==
                     selectedModel">{{model['name']}}
                     ({{model['count']}})</option>
                  </select>
               </div>
               <div class="make-container">
                  <label>Year from</label>
                  <select #minYearIp class="form-control"
                  (change)="onMinYearChange($event);">
                  <option value="">Any</option>
                  <option value="{{minYear}}" *ngFor="let minYear of
                  minYearList" [selected]="minYear ==
                  selectedMinYear">{{minYear}}</option>
                  </select>
               </div>
               <div class="make-container">
                  <label>Year to</label>
                  <select class="form-control"
                     (change)="onMaxYearChange($event);">
                     <option value="">Any</option>
                     <option value="{{maxYear}}" *ngFor="let maxYear of
                     maxYearList" [selected]="maxYear ==
                     selectedMaxYear">{{maxYear}}</option>
                  </select>
               </div>
            </form>
         </div>
      </div>
      <div class="row filter-options-ct">
         <div class="col-md-12">
            <!-- <div class="filter-option-ct" *ngIf="savedStatus != null">
               <span class="filter-option"
                   style="text-transform:uppercase;">{{savedStatus}}</span>
               <i class="fas fa-times-circle close-icon"
                   (click)="removeOption('status');"></i>
               </div>
               -->
            <div class="filter-option-ct" *ngIf="selectedMake != null">
               <span class="filter-option">{{selectedMake}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('make');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedModel != null">
               <span class="filter-option">{{selectedModel}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('model');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedMinYear != null">
               <span class="filter-option">{{selectedMinYear}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('minYear');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedMaxYear != null">
               <span class="filter-option">{{selectedMaxYear}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('maxYear');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedOdoMax != null ||
               selectedOdoMin != null && (selectedOdoMax != '0' &&
               selectedOdoMin != '0')">
               <span class="filter-option">{{selectedOdoMin}}<span
                  *ngIf="selectedOdoMax != null && selectedOdoMin !=
                  null">-</span>{{selectedOdoMax}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('odoMax');removeOption('odoMin');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedAutocheck != null">
               <span class="filter-option">{{selectedAutocheck}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('autocheck');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedAccidents != null">
               <span class="filter-option">{{selectedAccidents}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('accident');"></i>
            </div>
            <div class="filter-option-ct" *ngIf="selectedCarfax != null">
               <span class="filter-option">{{selectedCarfax}}</span>
               <i class="fas fa-times-circle close-icon"
                  (click)="removeOption('carfax');"></i>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="colo-md-12 col-12 custom-loader"
            *ngIf="!vehiclesLoaded">
            <img src="../../assets/images/loader.gif" height="300">
         </div>
         <div class="col-lg-12" *ngIf="vehiclesLoaded">
            <div class="row shop_container">
               <div class="col-md-4 col-6" *ngFor="let vehicle of
                  vehicles">
                  <app-product [vehicle]="vehicle"
                  [selectedStatus]="selectedStatus"
                  [loggedIn]="loggedIn"></app-product>
               </div>
            </div>
         </div>
         <div class="col-lg-12" *ngIf="vehicles.length == 0 &&
            vehiclesLoaded">
            <div class="row shop_container">
               <div class="col-md-12" style="display: block; text-align:
                  center;margin-top: 24px;">
                  <img src="../../assets/images/no_result_found.png"
                     alt="No result found">
               </div>
            </div>
         </div>
         <div infiniteScroll [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
      </div>
      <div class="colo-md-12 col-12 custom-loader" *ngIf="!finished">
         <img src="../../assets/images/loader.gif" height="300">
      </div>
   </div>
</div>
</div>
<app-footer></app-footer>
<app-scroll-up></app-scroll-up>