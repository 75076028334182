<!-- <app-loader></app-loader> -->
<!-- <app-header [activeMenu]="'REGISTER'"></app-header> -->

<!-- START HEADER -->
<div class="header_sticky_bar d-none" style="height: 67px;"></div>
<header class="header_wrap fixed-top header_with_topbar">
    <div class="bottom_header dark_skin main_menu_uppercase" style="box-shadow: 0 0 7px rgba(0,0,0,0.1);">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="/">
                    <img class="logo_dark" src="assets/images/logo_dark.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-expanded="false">
                    <span class="ion-android-menu"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li style="margin-top: 11px;">
                            <button class="btn btn-primary switch-to-webiste">SWITCH TO WEBSITE</button>
                        </li>
                        <li *ngIf="loggedIn"><a style="cursor: pointer;" (click)="logout();" class="nav-link nav_item"
                                title="Logout"><i class="linearicons-user"></i> Logout</a></li>
                    </ul>
                </div>
                <!-- <ul class="navbar-nav attr-nav align-items-center">
                    <li *ngIf="!loggedIn"><a [routerLink]="['/login']"  class="nav-link" title="Login" [ngClass]="{'login-active': activeMenu == 'LOGIN' ? true : false}"><i class="linearicons-user"></i> Login</a></li>
                    <li *ngIf="loggedIn"><a style="cursor: pointer;" (click)="logout();"  class="nav-link" title="Logout"><i class="linearicons-user"></i> Logout</a></li>
                </ul> -->
            </nav>
        </div>
    </div>
</header>
<!-- END HEADER -->

<nav class="main-menu">
    <ul>
        <li (click)="showDlrMenu();">
            <a [routerLink]="['/admin-panel']">
                <i class="fa fa-user fa-2x"></i>
                <span class="nav-text">
                    Dealer
                </span>
                <i class="fa" [ngClass]="{'fa-chevron-up': toggleDealerMenu, 'fa-chevron-down': !toggleDealerMenu}"
                    aria-hidden="true"></i>
            </a>
        </li>
    </ul>
    <ul class="dealer-submenu" *ngIf='toggleDealerMenu'>
        <li class="sub-menu">
            <a [routerLink]="['/admin-panel']">
                <i class="fa fa-bell fa-2x"></i>
                <span class="nav-text">
                    Dealer Request
                </span>
            </a>
        </li>
        <li class="sub-menu active">
            <a [routerLink]="['/dealers']">
                <i class="fa fa-file fa-2x"></i>
                <span class="nav-text">
                    Dealers
                </span>
            </a>
        </li>
    </ul>
</nav>

<div class="main-container-sidebar">
    <div class="container">
        <!-- <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-danger add-new-dlrshp" data-toggle="modal" data-target="#editmodal">ADD NEW DEALERSHIP</button>
                </div>
            </div> -->
        <div class="row">
            <div class="col-md-12">
                <p class="slet-dlr">
                    <!-- <select class="form-select select-dearshp" aria-label="Default select example">
                        <option selected>SELECT DEALERSHIP</option>
                        <option value="1">ALL</option>
                        <option value="2">ADVANCED AUTO GROUP INC</option>
                        <option value="3">BC MOTORS</option>
                    </select> -->
                    <span class="pull-right" style="margin-top: -13px;">NO OF DEALERS: {{this.allDealerList.length}}</span>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group search-field">
                    <input class="search-inp" matInput (keyup)="applyFilter($event.target.value);"
                        placeholder="Search">
                    <i class="fa fa-search search-icn" aria-hidden="true"></i>
                </div>

                <div style="overflow: auto;">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-full-table" id="my-table">

                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> SNO </th>
                            <td mat-cell *matCellDef="let element; let i = index;"> {{ (i+1) +
                                (scheduledOrdersPaginator.pageIndex * scheduledOrdersPaginator.pageSize) }} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> DATE </th>
                            <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="dlrname">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> DEALER NAME </th>
                            <td mat-cell *matCellDef="let element"> {{element.Dealername }} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="addrs">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> ADDRESS </th>
                            <td mat-cell *matCellDef="let element"> {{element.Dealeraddress}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="deptcontperson">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> DEPT CONTACT PERSON </th>
                            <td mat-cell *matCellDef="let element"> {{element.TDConperson}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="deptemail">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> DEPT EMAIL ADDRESS </th>
                            <td mat-cell *matCellDef="let element"> {{element.TDEmail}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="deptcontactph">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> DEPT DIRECT CONTACT PHONE NO </th>
                            <td mat-cell *matCellDef="let element"> {{element.TDContacno}} </td>
                        </ng-container>
    
                        <!-- <ng-container matColumnDef="bosemailid">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> BOS EMAIL </th>
                            <td mat-cell *matCellDef="let element">
                                <i class="fa fa-plus plus-clr" aria-hidden="true" data-toggle="modal"
                                    data-target="#addemail"></i>
                            </td>
                        </ng-container> -->
    
                        <ng-container matColumnDef="editdelete">
                            <th mat-header-cell *matHeaderCellDef class="mat-head-title"> EDIT | DELETE </th>
                            <td mat-cell *matCellDef="let element">
                                <i class="fa fa-pencil pencil-clr" aria-hidden="true" data-toggle="modal"
                                    data-target="#editmodal" (click)="editdearler(element.DealerID);"></i>
                                <i class="fa fa-trash delete-clr" aria-hidden="true"
                                    (click)="getDealerId(element.DealerID);" data-toggle="modal"
                                    data-target="#deleterequst"></i>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" showFirstLastButtons
                    #scheduledOrdersPaginator>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleterequst" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" id="add-email-mdl" role="document">
        <div class="modal-content">
            <div class="modal-header add-dlr-mdl-delete">
                <h5 class="modal-title dlr-mdl-lbl" id="exampleModalLabel">Delete Dealer</h5>
                <button type="button" class="close" #closedelete data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color: #fff;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div>Are you sure you want to delete?</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success create-btn" (click)="Deletedealer()">Delete</button>
                <button type="button" class="btn btn-danger create-btn" data-dismiss="modal">cancel</button>
            </div>
        </div>
    </div>
</div>
<!--Edit Modal -->
<div class="modal fade edit-modal-main" id="editmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" id="edit-mdl-dlr" role="document">
        <div class="modal-content">
            <form class="add-user-form" [formGroup]="DealerForm" (ngSubmit)="Onsubmit()">
                <div class="modal-header add-dlr-mdl-hrd">
                    <h5 class="modal-title dlr-mdl-lbl" id="exampleModalLabel">Update Dealership</h5>
                    <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" style="color: #fff;">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="add-dlr-head">Enter Dealership Details</p>
                    <div class="colo-md-12 col-12 custom-loader" *ngIf="loadingDealer" style="text-align: center;">
                        <img src="../../assets/images/loader.gif" height="300" alt="">
                    </div>
                    <div *ngIf="!loadingDealer">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Name of the Dealership"
                                        formControlName="Dealername">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="file" class="form-control dlr-det" placeholder="Logo"
                                        style="color: inherit;">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Email"
                                        formControlName="DealerEmail">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Address"
                                        formControlName="Dealeraddress">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="City"
                                        formControlName="City">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="State"
                                        formControlName="DState">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Zip"
                                        formControlName="Zipcode">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <mat-chip-list #chipList aria-label="">
                                        <input placeholder="Emails to send BOS"
                                            class="form-control shwplhldr emailslistvw dlr-det"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
                                            autocomplete="doNotAutoComplete">
                                        <span class="col-md-12 errorBOSemails" *ngIf="emailValidBOS">Enter valid email
                                            ID</span>
                                    </mat-chip-list>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 alignchip">
                                <mat-chip *ngFor="let BOSmail of BOSemails" [removable]="removable"
                                    (removed)="remove(BOSmail)">
                                    {{BOSmail.email}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="add-dlr-head">Title Department</p>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Contact Person"
                                        formControlName="TDConperson">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="email" class="form-control dlr-det" placeholder="Email"
                                        formControlName="TDEmail">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control dlr-det" placeholder="Contact Phone Number"
                                        formControlName="TDContacno">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success create-btn" (click)="Onsubmit()">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- START FOOTER -->
<footer class="bg_gray">
    <div class="footer_top small_pt pb_20">
        <div class="footer-container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="widget">
                        <div class="footer_logo">
                            <a href="#"><img
                                    src="../../../../assets/images/logo_dark.png"
                                    alt="logo" /></a>
                        </div>
                        <p class="mb-3">
                            AutoBuy Direct provides the simplest and fastest way to buy Car, Truck, SUV, Motorcycle or Commercial Vehicle!
                        </p>
                        <ul class="contact_info">
                            <li>
                                <i class="ti-email"></i>
                                <a href="mailto:info@wepaythemax.com">info@wepaythemax.com</a>
                            </li>
                            <li>
                                <i class="ti-mobile"></i>
                                <a href="tel:+15613290988">561-329-0988</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="widget" style="float: right;">
                        <h6 class="widget_title">Useful Links</h6>
                        <ul class="widget_links">
                            <li><a [routerLink]="['/inventory']">Inventory</a></li>
                            <li><a [routerLink]="['/contact']">Contact Us</a></li>
                            <li><a [routerLink]="['/login']">Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran">
        <div class="footer-container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <p class="mb-lg-0 text-center">© 2020 All Rights Reserved by
                        Autobuy Direct</p>
                </div>
                <div class="col-lg-4 order-lg-first"></div>
            </div>
        </div>
    </div>
</footer>
<!-- END FOOTER -->
<app-scroll-up></app-scroll-up>