import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from "../services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { AuthService } from 'src/app/services/auth.service';
export interface BOSmail {
  email: string;
}

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AdminPanelComponent implements OnInit {
  BOSemails: BOSmail[] = [];
  displayedColumns: string[] = ['sno', 'reqdate', 'dlrname', 'addrs', 'tildeptcontperson', 'tildeptemail', 'tildeptcontactph',  'acceptaction', 'deleteaction'];
  toggleDealerMenu: boolean = true;
  allAdminList:any=[];
  addOnBlur = true;
  removable = true;
  emailValidBOS = false;
  getSno:any="";
  // dataSource :any=[];
  Sno:any = "";
  loggedIn: boolean = false;
  loadingDealer:boolean=false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dataSource: MatTableDataSource<any>;
  @ViewChild('scheduledOrdersPaginator') paginator: MatPaginator;
  
 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closedelete') closedelete;
  public AdminForm: FormGroup;
  constructor(private apiService: ApiService,  private authService: AuthService,
     private toastr: ToastrService,public _fb: FormBuilder,) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    this.getAdminDetials(0);
  
    this.AdminForm = this._fb.group({
      DealerID :[""],
      Dealername: [""],
      Dealeraddress: [""],
      City: [""],
      DState: [""],
      Zipcode: [""],
      DealerEmail: [""],
      TDConperson: [""],
      TDEmail: [""],
      TDContacno: [""],
      
      BOSOption: [""],
      status: [""],
    });
  }
  // ngAfterViewInit() {
  //   setTimeout(() => (this.dataSource.paginator = this.paginator));
  //   // console.log(this.dataSource.paginator);
  // }
  logout() {
    this.authService.logout();
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our BOSmail
    if ((value || '').trim()) {
      var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      if (reg.test(value)) {  
       this.emailValidBOS = false;
       console.log(value.trim());
        this.BOSemails.push({email: value.trim()});
      } else {
        // alert('please enter valid email');
        this.emailValidBOS = true;
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(BOSmail: BOSmail): void {
    const index = this.BOSemails.indexOf(BOSmail);

    if (index >= 0) {
      this.BOSemails.splice(index, 1);
    }
  }
  getAdminDetials(id) {
    // this.loader = true;
    this.apiService.AdminRequest(id).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.allAdminList = resultData;
        console.log(this.allAdminList.length)
        this.dataSource = new MatTableDataSource(this.allAdminList);
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      },
      (error) => {}
    );
  }
  editdearler(id)
  {
    this.apiService.AdminRequest(id).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.AdminForm.get("Dealername").setValue(resultData[0].Dealername);
        this.AdminForm.get("Dealeraddress").setValue(resultData[0].Dealeraddress);
        this.AdminForm.get("City").setValue(resultData[0].City);
        this.AdminForm.get("DState").setValue(resultData[0].DState);
        this.AdminForm.get("Zipcode").setValue(resultData[0].Zipcode);
        this.AdminForm.get("DealerEmail").setValue(resultData[0].DealerEmail);
        this.AdminForm.get("TDConperson").setValue(resultData[0].TDConperson);
        this.AdminForm.get("TDEmail").setValue(resultData[0].TDEmail);
        this.AdminForm.get("TDContacno").setValue(resultData[0].TDContacno);
        this.Sno=resultData[0].Sno;
        if(resultData[0].EmailBos != "" && resultData[0].EmailBos != null)
        {
          var array = resultData[0].EmailBos.split(',');
          array.forEach((value, index) => {
          console.log(value.name);
          this.BOSemails.push({email: value.trim()});
        });
          // this.BOSemails.push({email: resultData[0].EmailBos});
        }
       
        // console.log(array);
       // this.BOSemails.push({email: resultData[0].EmailBos});
        // resultData[0].EmailBos.forEach((value, index) => {
        //   console.log(value.name);
        //   this.BOSemails.push({email: value.name.trim()});
        // });

       
      },
      (error) => {}
    );
  }
  Onsubmit()
  {
    this.loadingDealer=true;
    let data = {
      Sno:(this.Sno!="")?this.Sno:"",
      DealerID :0,
      Dealername: this.AdminForm.value.Dealername,
      Dealeraddress: this.AdminForm.value.Dealeraddress,
      City: this.AdminForm.value.City,
      DState: this.AdminForm.value.DState,
      Zipcode: this.AdminForm.value.Zipcode,
      DealerEmail:this.AdminForm.value.DealerEmail,
      TDConperson: this.AdminForm.value.TDConperson,
      TDEmail: this.AdminForm.value.TDEmail,
      TDContacno: this.AdminForm.value.TDContacno,
      EmailBos:this.BOSemails,
      BOSOption: "",
      status: "y",
    };
    console.log(data);
    this.apiService.DealearAccept(data).subscribe((resultData: any) => {
     
      console.log(resultData);
      this.loadingDealer=false;
      if (resultData.status == "1") {
        this.toastr.success(resultData.msg, null, {
          timeOut: 3000,
        });
      }
      if (resultData.status == "0") {
        this.toastr.error(resultData.msg, null, {
          timeOut: 3000,
        });
      }
      this.BOSemails=[];
      this.AdminForm.reset();
      this.getAdminDetials(0);
      //this.modal.close();

      this.closebutton.nativeElement.click();
    },(error) => {});
  }
  showDlrMenu() {
    this.toggleDealerMenu = !this.toggleDealerMenu;
  }
  closebtn()
  {
    this.BOSemails=[];
    this.AdminForm.reset();
  }
  getsno(sno){
  this.getSno = sno;
  }
  applyFilter(FilterValue: string) {
    this.dataSource.filter = FilterValue.trim().toLowerCase();
    
  }
  Deletedealerreq() {
    this.getSno = (this.getSno != "")?this.getSno:"";
    this.apiService.deletedealerreq(this.getSno).subscribe(
      (resultData: any) => {
        if (resultData.status == "1") {
          this.toastr.success("Deleted successfully", null, {
            timeOut: 3000,
          });
          this.closedelete.nativeElement.click();
          this.getAdminDetials(0);
      
  
        }
      },
      (error) => {}
    );
  }
}
