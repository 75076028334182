<app-header [activeMenu]="'SETTING'"></app-header>
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
   <!-- End Google Tag Manager -->
   
   <!-- Google Tag Manager (noscript) -->
   <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
   height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
   <!-- End Google Tag Manager (noscript) -->
<div class="section">
   <div class="container">
      <i class="fas fa-arrow-left arrow-back" (click)="navToHome();"><span class="back-txt"><b>BACK</b></span></i>
      <div class="colo-md-12 col-12 custom-loader" *ngIf="settingloadding">
         <img src="../../assets/images/loader.gif" height="300" alt="">
      </div>
      <div class="col-lg-12" *ngIf="!settingloadding" style="margin-top: 24px;">
         <div class="row">
            <form class="col-md-8 col-8" [formGroup]="SettingForm" (ngSubmit)="savesetting()">
               <div class="row pdig-btm">
                  <div class="col-md-3 form-group">
                     <label for="">Make</label>
                     <select class="form-control" (change)="onMakeChange($event);" formControlName="make" name="" id="">
                        <option value="">Any</option>
                        <option value="{{make['name']}}" *ngFor="let make of
                     makeList" [selected]="make['name'] == selectedMake">{{make['name']}} </option>
                     </select>
                  </div>
                  <div class="col-md-3 form-group">
                     <label for="">Model</label>
                     <select class="form-control" (change)="onModelChange($event);" formControlName="model" name=""
                        id="">
                        <option value="">Any</option>
                        <option value="{{model['name']}}" *ngFor="let model
                     of modelList" [selected]="model['name'] == selectedModel">{{model['name']}}</option>
                     </select>
                  </div>
                  <div class="col-md-3 form-group">
                     <label for="">Year from</label>
                     <select #minYearIp class="form-control" formControlName="yearFrom"
                        (change)="onMinYearChange($event);" name="" id="">
                        <option value="" [selected]="selectedMinYear == null">Any</option>
                        <option value="{{minYear}}" *ngFor="let minYear of
                  minYearList" [selected]="minYear == selectedMinYear">{{minYear}}</option>
                     </select>
                  </div>
                  <div class="col-md-3 form-group">
                     <label for="">Year to</label>
                     <select class="form-control" (change)="onMaxYearChange($event);" formControlName="yearTo" name=""
                        id="">
                        <option value="" [selected]="selectedMaxYear == null">Any</option>
                        <option value="{{maxYear}}" *ngFor="let maxYear of
                     maxYearList" [selected]="maxYear == selectedMaxYear">{{maxYear}}</option>
                     </select>
                  </div>
               </div>
               <!-- <div class="row pdig-btm form-group">
                  <div class="col-md-10">
                     <label for="">Odometer</label>
                     <ng5-slider [(value)]="odoMinValue" [(highValue)]="odoMaxValue" [mat-options]="mat-options"></ng5-slider>
                  </div>
               </div> -->
               <div class="row pdig-btm ">
                  <div class="col-md-12">
                     <label for="">Odometer</label>
                     <div class="row">
                        <div class="col-md-3">
                           <label for="">Minimum</label>
                           <mat-select class="form-control accident_select"   placeholder="Select Min"formControlName="odoMin" name="" id="">
                          
                           <mat-option value="0">0</mat-option>
                           <mat-option value="10000">10000</mat-option>
                           <mat-option value="20000">20000</mat-option>
                           <mat-option value="30000">30000</mat-option>
                           <mat-option value="40000">40000</mat-option>
                           <mat-option value="50000">50000</mat-option>
                           <mat-option value="60000">60000</mat-option>
                           <mat-option value="70000">70000</mat-option>
                           <mat-option value="80000">80000</mat-option>
                           <mat-option value="90000">90000</mat-option>
                           <mat-option value="100000">100000</mat-option>
                           <mat-option value="110000">110000</mat-option>
                           <mat-option value="120000">120000</mat-option>
                           <mat-option value="130000">130000</mat-option>
                           <mat-option value="140000">140000</mat-option>
                           <mat-option value="150000">150000</mat-option>
                           <mat-option value="160000">160000</mat-option>
                           <mat-option value="170000">170000</mat-option>
                           <mat-option value="180000">180000</mat-option>
                           <mat-option value="190000">190000</mat-option>
                           <mat-option value="105000">200000</mat-option>

                          </mat-select>
                          <!-- <div *ngIf="odoMin_staus">
                           <span class="error-msg">Minmum value greater than enter Maxmum</span>
                        </div> -->

                        </div>

                        <div class="col-md-3">
                           <label for="">Maximum</label>
                           <div class="form-group">
                            
                              <mat-select class="form-control accident_select" placeholder="Select Max" aria-placeholder="Maximum" formControlName="odoMaX"  name="" id="">
                                
                                <mat-option value="200000">200000</mat-option>
                                 <mat-option value="190000">190000</mat-option>
                                 <mat-option value="180000">180000</mat-option>
                                 <mat-option value="170000">170000</mat-option>
                                 <mat-option value="160000">160000</mat-option>
                                 <mat-option value="150000">150000</mat-option>
                                 <mat-option value="140000">140000</mat-option>
                                 <mat-option value="130000">130000</mat-option>
                                 <mat-option value="120000">120000</mat-option>
                                 <mat-option value="110000">110000</mat-option>
                                 <mat-option value="100000">100000</mat-option>
                                 <mat-option value="90000">90000</mat-option>
                                 <mat-option value="80000">80000</mat-option>
                                 <mat-option value="70000">70000</mat-option>
                                 <mat-option value="60000">60000</mat-option>
                                 <mat-option value="50000">50000</mat-option>
                                 <mat-option value="40000">40000</mat-option>
                                 <mat-option value="30000">30000</mat-option>
                                 <mat-option value="20000">20000</mat-option>
                                 <mat-option value="10000">10000</mat-option>
                                 <mat-option value="0">0</mat-option>
      
                                </mat-select>
                                <div *ngIf="odoMaX_staus">
                                 <span class="error-msg">Maximum value  should be greater than  Minimum value</span>
                              </div>

                           </div>
                        </div>

                     </div>
                  </div>
               </div>
               <!-- <div class="row pdig-btm form-group">
               <div class="col-md-10">
                   <label for="">Price</label>
                   <ng5-slider [(value)]="priceMin" [(highValue)]="PriceMax" [mat-options]="mat-options1"></ng5-slider>
               </div>
               </div> -->
               <div class="row pdig-btm ">
                  <div class="col-md-12">
                     <label for="">Reports</label>
                     <div class="row ">
                        <div class="col-md-3">
                           <label for="">Autocheck</label>

                           <mat-form-field>
                              <mat-select class="form-control accident_select" multiple
                                 ((change)="onAutoChange($event);" formControlName="autocheck" name="" id="">

                                 <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </div>

                        <div class="col-md-3">
                           <label for="">Carfax</label>
                           <mat-form-field>
                              <mat-select class="form-control accident_select" multiple
                                 (change)="oncarfaxChange($event);" formControlName="carfax" name="" id="">
                                 <mat-option value="CLEAN">CLEAN</mat-option>
                                 <mat-option value="NOT CLEAN">NOT CLEAN</mat-option>


                              </mat-select>
                           </mat-form-field>
                        </div>

                     </div>
                  </div>
               </div>
               <div class="row pdig-btm">
                  <div class="col-md-3">
                     <label for="">Filter Name</label>
                  </div>
                  <div class="col-md-8 ">
                     <div class="form-group sr-name">
                        <input type="text" class="form-control schName capitalize" formControlName="searchName">
                        <div *ngIf="SettingForm.controls.searchName.invalid">
                           <span class="error-msg" *ngIf="SettingForm.controls.searchName.errors.required">Filter Name
                              is required</span>
                        </div>

                     </div>
                  </div>
               </div>
               <div class="row filter-options-ct"
                  *ngIf="selectedMake != null || selectedModel != null || 
               selectedMinYear != null || selectedMaxYear != null || selectedInprogress != null || selectedInventory != null || selectedAll !=null">
                  <div class="col-md-12">
                     <div class="filter-option-ct" *ngIf="selectedMinYear != null || selectedMaxYear != null ">
                        <span class="filter-option">{{selectedMinYear}}<span
                              *ngIf="selectedMinYear != null && selectedMaxYear != null"> -
                           </span>{{selectedMaxYear}}</span>
                        <i class="fas fa-times-circle close-icon"
                           (click)="removeOption('minYear');removeOption('maxYear');"></i>
                     </div>
                     <!-- <div class="filter-mat-option-ct" *ngIf="selectedMaxYear != null">
                     <span class="filter-mat-option">{{selectedMaxYear}}</span>
                     <i class="fas fa-times-circle close-icon" (click)="removeOption('maxYear');"></i>
                     </div> -->
                     <div class="filter-option-ct" *ngIf="selectedMake != null">
                        <span class="filter-option">{{selectedMake}}</span>
                        <i class="fas fa-times-circle close-icon" (click)="removeOption('make');"></i>
                     </div>
                     <div class="filter-option-ct" *ngIf="selectedModel != null">
                        <span class="filter-option">{{selectedModel}}</span>
                        <i class="fas fa-times-circle close-icon" (click)="removeOption('model');"></i>
                     </div>
                     <div class="filter-option-ct" *ngIf="selectedInprogress != null">
                        <span class="filter-option">{{selectedInprogress}}</span>
                        <i class="fas fa-times-circle close-icon" (click)="removeOption('Inprogress');"></i>
                     </div>
                     <div class="filter-option-ct" *ngIf="selectedInventory != null">
                        <span class="filter-option">{{selectedInventory}}</span>
                        <i class="fas fa-times-circle close-icon" (click)="removeOption('Inventory');"></i>
                     </div>
                     <div class="filter-option-ct" *ngIf="selectedAll != null">
                        <span class="filter-option">{{selectedAll}}</span>
                        <i class="fas fa-times-circle close-icon" (click)="removeOption('All');"></i>
                     </div>
                  </div>
               </div>
               <div class="row cntr">
                  <button type="submit" class="btn  btn-success btn-sz">{{btnclick}}</button>
                  <button type="button" class="btn  btn-primary btn-sz" (click)="resetform();">Clear</button>
                  <!-- <button type="button" class="btn  btn-danger btn-sz">Close</button> -->
               </div>
            </form>
            <div class="col-md-4 col-4">
               <span style="color: black;font-weight: bold;">Saved Filter</span>
               <div class="card" style="margin-top: 2%; height: 97%;">
                  <div class="row filter-options-save">
                     <div class="col-md-12">
                        <!-- [innerHTML]="htmlData" -->
                        <span *ngFor="let filterInfo of filterdata">
                           <div class="filter-option-save"
                              [ngClass]="[filterInfo.id == selectid ? 'status-filter' : '']">
                              <div class="filter-option filterValue" (click)="getfilterdata(filterInfo.id)"
                                 style="float: left;"> {{filterInfo.savedName}}</div>
                              <i class="fas fa-times-circle close-icon" (click)="removesetting(filterInfo.id);"></i>
                           </div>
                        </span>
                        <!-- <div class="filter-mat-option-save" >
                           <span class="filter-mat-option"> Testing1</span>
                           <i class="fas fa-times-circle close-icon" (click)="removeOption('minYear');"></i>
                           </div> -->
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>
<app-scroll-up></app-scroll-up>