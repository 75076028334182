import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertAmount'
})
export class ConvertAmountPipe implements PipeTransform {

  transform(value: string): string {
    console.log("value: ", value);

    let convertedAmount = value ? (value.toString()).replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,") : value;

    return "$ " + convertedAmount;
  }

}
