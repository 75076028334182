<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'LOGIN'"></app-header>

<div class="main_content">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">

                <div class="colo-md-12 col-12 custom-loader" *ngIf="loggingIn">
                    <img src="../../assets/images/loader.gif" height="300" alt="">
                </div>

                <div class="col-xl-6 col-md-10" *ngIf="!loggingIn">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div class="heading_s1">
                                <h3>Login</h3>
                            </div>
                            <p class="error-msg" *ngIf="loginError">Username or Password is incorrect</p>
                            <form method="post" [formGroup]="loginForm" (ngSubmit)="login()">
                                <div class="form-group">
                                    <input type="text" (change)="clearMsg();" required=""  class="form-control" formControlName="username" placeholder="Username">
                                    <div *ngIf="loginSubmitted && loginForm.controls.username.invalid">
                                        <span class="error-msg" *ngIf="loginForm.controls.username.errors.required">Username is required</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input class="form-control" (change)="clearMsg();" required="" type="password" formControlName="password" placeholder="Password">
                                    <div *ngIf="loginSubmitted && loginForm.controls.password.invalid">
                                        <span class="error-msg" *ngIf="loginForm.controls.password.errors.required">Password is required</span>
                                    </div>
                                </div>
                                <div class="login_footer form-group">
                                    <div class="chek-form">
                                        <div class="custome-checkbox">
                                            <input class="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" value="">
                                            <label class="form-check-label" for="exampleCheckbox1"><span>Remember me</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-fill-out btn-block" name="login">Log in</button>
                                </div>
                            </form>
                            <div class="form-note text-center">Don't Have an Account? <a class="reg-nw" [routerLink]="['/register']" routerLinkActive="router-link-active" >Register now</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>