import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { DetailsComponent } from './product/details/details.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { SubscriptionComponent } from './shared/components/popup/subscription/subscription.component';
import { ScrollUpComponent } from './shared/components/scroll-up/scroll-up.component';
import { NewsletterComponent } from './shared/components/newsletter/newsletter.component';
import { InventoryComponent } from './inventory/inventory.component';
import { environment } from 'src/environments/environment';
import { ReplaceUnderscorePipePipe } from './pipes/replace-underscore-pipe.pipe';
import { InsertCommaPipePipe } from './pipes/insert-comma-pipe.pipe';
import { RegisterComponent } from './register/register.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactComponent } from './contact/contact.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ListFilterPipe } from './pipes/list-filter.pipe';
import { LightboxModule } from 'ngx-lightbox';
import { ConvertAmountPipe } from './pipes/convert-amount.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { SettingComponent } from './setting/setting.component';
import { Ng5SliderModule } from 'ng5-slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RelativeTimeDayPipe } from './pipes/relative-time-day.pipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { DealersComponent } from './admin-panel/dealers/dealers.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductComponent,
    FooterComponent,
    DetailsComponent,
    LoginComponent,
    HeaderComponent,
    LoaderComponent,
    SubscriptionComponent,
    ScrollUpComponent,
    NewsletterComponent,
    InventoryComponent,
    ReplaceUnderscorePipePipe,
    InsertCommaPipePipe,
    RegisterComponent,
    ContactComponent,
    ListFilterPipe,
    ConvertAmountPipe,
    RelativeTimePipe,
    SettingComponent,
    RelativeTimeDayPipe,
    ChangePasswordComponent,
    CreateUserComponent,
    AdminPanelComponent,
    DealersComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    InfiniteScrollModule,
    ScrollToModule.forRoot(),
    LightboxModule,
    Ng5SliderModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule
  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent],

})
export class AppModule { }
