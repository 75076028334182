import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DetailsComponent } from './product/details/details.component';
import { LoginComponent } from './login/login.component';
import { InventoryComponent } from './inventory/inventory.component';
import { RegisterComponent } from './register/register.component';
import { ContactComponent } from './contact/contact.component';
import { SettingComponent } from './setting/setting.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { DealersComponent } from './admin-panel/dealers/dealers.component';

const routes: Routes = [
  { path: '', redirectTo: 'inventory', pathMatch: 'full' },
  { path: 'inventory', component: InventoryComponent },
  { path: 'detail/:leadId', component: DetailsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'setting', component: SettingComponent },
  { path: 'postlogin/:leadId', component: LoginComponent },
 
  { path: 'change-password/:Id', component: ChangePasswordComponent },
  { path: 'create-user', component: CreateUserComponent },
  { path: 'admin-panel', component: AdminPanelComponent },
  { path: 'dealers', component: DealersComponent },
  { path: '**', redirectTo: 'inventory'},
];
// { path: 'change-password/:Id/:password', component: ChangePasswordComponent },
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
