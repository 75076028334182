// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_root_url: 'https://api.autobuydirect.com',
  firebase: {
    apiKey: "AIzaSyDLx2kQ8Oxo1knkXQsIx2IE9VVxD58SEpk",
    authDomain: "autobuy-direct-retail-9a95b.firebaseapp.com",
    databaseURL: "https://autobuy-direct-retail-9a95b.firebaseio.com",
    projectId: "autobuy-direct-retail-9a95b",
    storageBucket: "autobuy-direct-retail-9a95b.appspot.com",
    messagingSenderId: "767227585902",
    appId: "1:767227585902:web:02143021a04eb9ddaf4d7f",
    measurementId: "G-FL8PNR2JH3"
  },

  //live
  firebaseRef: {
    vehicles: 'live/vehicles',
    makeList: 'live/makeList',
    modelList: 'live/modelList',
    vehicleInfo: 'live/vehicle-info',
    soldmake:'live/sold/makeList',
    soldmodel:'live/sold/modelList',
    soldvehicles:'live/sold/vehicles',
    soldvehicleInfo:'live/sold/vehicle-info'
  }, 
   //dev
  // firebaseRef: {
  //   vehicles: 'dev/vehicles',
  //   makeList: 'dev/makeList',
  //   modelList: 'dev/modelList',
  //   vehicleInfo: 'dev/vehicle-info',
  //   soldmake:'dev/sold/makeList',
  //   soldmodel:'dev/sold/modelList',
  //   soldvehicles:'dev/sold/vehicles',
  //   soldvehicleInfo:'dev/sold/vehicle-info'
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
