import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  contactSubmitted: boolean = false;
  contactUsClicked: boolean = false;
  successMsg: any;

  errorMsg: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router
  ) {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    localStorage.setItem('scrollPosition', '0');
  }

  contactUs() {
    console.log(this.contactForm);
    this.contactSubmitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    let data = {
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      phone: this.contactForm.value.phone,
      email: this.contactForm.value.email,
      subject: this.contactForm.value.subject,
      message: this.contactForm.value.message,
    };

    this.contactUsClicked = true;
    this.apiService.sendMessage(data).subscribe((resultData) => {
      console.log("ResultData: ", resultData);
      this.contactUsClicked = false;
      if(resultData['status'] == "1") {
        console.log('Message sent successfully');
        this.resetContactForm();
        this.contactSubmitted = false;
        this.successMsg = "Message sent successfully";
      } else {
        this.successMsg = null;
        this.errorMsg = "Something went wrong. Try again!";
        console.log('Something went wrong. Try again!');
      }
    }, (error) => {
      this.contactUsClicked = false;
      this.successMsg = null;
      this.errorMsg = "Something went wrong. Try again!";
      console.log(error);
      console.log('Something went wrong. Try again!');
    });
  }

  resetContactForm() {
    this.contactForm.controls['firstName'].setValue(null);
    this.contactForm.controls['lastName'].setValue(null);
    this.contactForm.controls['phone'].setValue(null);
    this.contactForm.controls['email'].setValue(null);
    this.contactForm.controls['subject'].setValue(null);
    this.contactForm.controls['message'].setValue(null);
  }

  clearMsg() {
    this.contactSubmitted = false;
  }

}
