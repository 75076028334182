import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import {MatChipInputEvent} from '@angular/material/chips';


export interface BOSmail {
  name: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  registerSubmitted: boolean = false;
  registeringDealer: boolean = false;
  showThanksContnt: boolean = false;
  isCheckedbos:boolean = true;
  isChecked_bos:boolean = true;
  shwRegCont: boolean = true;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  emailValidBOS = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  BOSemails: BOSmail[] = [];
  BOSOption:any=[];
  errorMsg: any;
  emailPattern = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"; 
  dealeremailBind:any ="";
  titleemailBind:any="";
  showBOSoption:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router
  ) {
    this.registerForm = this.formBuilder.group({
      dealerName: ['', Validators.required],
      dealerEmail:['', [Validators.required,Validators.pattern(this.emailPattern)]],
      dealerAddress: ['', Validators.required],
      city:['', Validators.required],
      state:['', Validators.required],
      zip:['', Validators.required],
      titleDptContPerson: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      titleDptEmailAddress: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      titleDptDirectContactPhNo: ['', Validators.required],
    });
  }
  

  ngOnInit(): void {
   
    localStorage.setItem('scrollPosition', '0');
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our BOSmail
    if ((value || '').trim()) {
      //var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      var reg = /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (reg.test(value)) {  
        this.emailValidBOS = false;
        console.log(value);
        this.BOSemails.push({name: value.trim()});
      } else {
      //  alert('please enter valid email');
        this.emailValidBOS = true;
      }
    }
    

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(BOSmail: BOSmail): void {
    const index = this.BOSemails.indexOf(BOSmail);

    if (index >= 0) {
      this.BOSemails.splice(index, 1);
    }
  }
  fieldsChange(evt)
  {
    if(evt.target.checked)
    {
      this.registerForm.get('titleDptEmailAddress').setValue(this.registerForm.value.dealerEmail);
    }
    else
    {
      this.registerForm.get('titleDptEmailAddress').setValue("");
    }
  }
  emailoption(evt){
    if(evt.target.checked)
    {
      this.isCheckedbos = true;
    }
    else
    {
      this.isCheckedbos = false;
    }
  }
  emailoption_bos(evt)
  {
    if(evt.target.checked)
    {
      this.isChecked_bos = true;
    }
    else
    {
      this.isChecked_bos = false;
    }
  }
  onDealeremail(event)
  {
    console.log(event);
    if(event.target.value != "")
    {
      this.dealeremailBind = event.target.value;
      this.showBOSoption = true;

    }
  }
  onTitleemail(event)
  {
    if(event.target.value != "")
    {
      if(event.target.value != this.dealeremailBind)
      {
        this.titleemailBind = event.target.value;
        this.showBOSoption = true;
      }
   

    }
  }
  registerDealer() {
    // console.log(this.registerForm);
    this.registerSubmitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    // if(this.registerForm.value.password != this.registerForm.value.confirmPassword) {
    //   this.errorMsg = 'Passwords do not match';
    //   return;
    // }
     if(this.isChecked_bos && this.isCheckedbos)
     {
      this.BOSOption =[this.dealeremailBind,this.titleemailBind]
     }
     else if(this.isChecked_bos)
     {
      this.BOSOption =[this.titleemailBind]
     }
     else if(this.isCheckedbos)
     {
      this.BOSOption =[this.dealeremailBind]
     }
     else
     {
      this.BOSOption=[];
     }
    let data = {
      Dealername: this.registerForm.value.dealerName,
      Dealeraddress: this.registerForm.value.dealerAddress,
      DealerEmail:this.registerForm.value.dealerEmail,
      City:this.registerForm.value.city,
      DState:this.registerForm.value.state,
      Zipcode:this.registerForm.value.zip,
      TDConperson: this.registerForm.value.titleDptContPerson,
      TDEmail: this.registerForm.value.titleDptEmailAddress,
      TDContacno: this.registerForm.value.titleDptDirectContactPhNo,
      emailBoS: this.BOSemails,
       BOSOption:this.BOSOption
    };
  console.log(data);
   this.registeringDealer = true;
    this.apiService.registerNewDealer(data).subscribe((resultData) => {
      console.log("ResultData: ", resultData['result']);
      if(resultData['status'] == 1) {
        this.registeringDealer = false;
        this.shwRegCont = false;
        this.showThanksContnt = true;
        // this.router.navigate(['/inventory']);
      } else {
        this.errorMsg = 'Something went wrong. Try again!';
        this.registeringDealer = false;
      }
    }, (error) => {
      this.errorMsg = 'Something went wrong. Try again!';
      this.registeringDealer = false;
      console.log(error);
    });
  }

  clearMsg() {
    this.registerSubmitted = false;
  }

  

}
