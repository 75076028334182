<!-- <app-loader></app-loader> -->
<!-- <app-subscription></app-subscription> -->
<app-header [activeMenu]="'HOME'"></app-header>
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJT4V2W');</script> -->
    <!-- End Google Tag Manager -->
    
    <!-- Google Tag Manager (noscript) -->
    <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJT4V2W"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
    <!-- End Google Tag Manager (noscript) -->
<!-- START SECTION BANNER -->
<div class="banner_section slide_medium shop_banner_slider
    staggered-animation-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div id="carouselExampleControls" class="carousel slide
                    light_arrow" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active background_bg"
                            style="background-image:
                            url(https://www.autobuydirect.com/img/signup.jpg);background-position: bottom;">
                            <div class="banner_slide_content
                                banner_content_inner">
                                <div class="col-lg-8 col-10">
                                    <div class="banner_content overflow-hidden">
                                        <h2 class="staggered-animation"
                                            data-animation="slideInLeft"
                                            data-animation-delay="1s" style="color: white;">Find your dream car</h2>
                                        <a class="btn btn-fill-out rounded-0
                                            staggered-animation text-uppercase"
                                            [routerLink]="['/inventory']"
                                            data-animation="slideInLeft"
                                            data-animation-delay="1.5s">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item background_bg"
                            style="background-image:
                            url(https://www.autobuydirect.com/img/login.jpg);">
                            <div class="banner_slide_content
                                banner_content_inner">
                                <div class="col-lg-8 col-10">
                                    <div class="banner_content overflow-hidden">
                                        <h2 class="staggered-animation"
                                            data-animation="slideInLeft"
                                            data-animation-delay="1s" style="color: white;">Find the trending cars</h2>
                                        <a class="btn btn-fill-out rounded-0
                                            staggered-animation text-uppercase"
                                            [routerLink]="['/inventory']"
                                            data-animation="slideInLeft"
                                            data-animation-delay="1.5s">Shop Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators indicators_style1">
                        <li data-target="#carouselExampleControls"
                            data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleControls"
                            data-slide-to="1"></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION BANNER -->

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>