<!-- <app-loader></app-loader> -->
<app-header [activeMenu]="'CREATEUSER'"></app-header>

<div class="container head-cont">
    <div class="row">
        <div class="col-md-12">
            <h3>Create User</h3>
        </div>
    </div>
</div>
<div class="container tab-containr">


    <div class="colo-md-12 col-12 custom-loader" style="text-align: center;" *ngIf="loader">
        <img src="../../assets/images/loader.gif" height="300" alt="">
    </div>
    <ul class="nav nav-tabs" role="tablist" *ngIf="!loader">
        <li class="nav-item" [ngClass]="activeTab == 1? 'active': ''">
            <a class="nav-link  tab-name" [ngClass]="activeTab == 1? 'active': ''" data-toggle="tab" href="#tabs-1"
                role="tab" (click)="cleartab();activeTab = 1;">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>{{bttntext}} User</a>
        </li>
        <li class="nav-item" [ngClass]="activeTab == 2? 'active': ''">
            <a class="nav-link tab-name" [ngClass]="activeTab == 2? 'active': ''" data-toggle="tab" href="#tabs-2"
                role="tab" (click)="cleartab();activeTab = 2;">
                <i class="fa fa-eye eye-icon" aria-hidden="true"></i>View Users</a>
        </li>
    </ul><!-- Tab panes -->
    <div class="tab-content" *ngIf="!loader">
        <div class="tab-pane " [ngClass]="activeTab == 1? 'active': 'fade'" id="tabs-1" role="tabpanel">
            <form class="add-user-form" [formGroup]="CreateUser" (ngSubmit)="Onsubmit()">
                <div class="row" *ngIf="dearlername">
                    <div class="col-md-12">
                        <label class="view-delr">Dealer: {{dearlername}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" (change)="clearMsg();" class="form-control" placeholder="First Name" formControlName="FName">
                            <div *ngIf="userSubmitted && CreateUser.controls.FName.invalid">
                                <span class="error-msg" *ngIf="CreateUser.controls.FName.errors.required">First
                                    Name is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" (change)="clearMsg();" type="text" placeholder="Last Name" formControlName="LName">
                            <!-- <div *ngIf="userSubmitted && CreateUser.controls.FName.invalid">
                                <span class="error-msg" *ngIf="CreateUser.controls.LName.errors.required">Last
                                    Name is required</span>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" type="text" (change)="clearMsg();" placeholder="User Name" formControlName="UserName"
                                autocomplete="doNotAutoComplete">
                            <div *ngIf="userSubmitted && CreateUser.controls.UserName.invalid">
                                <span class="error-msg" *ngIf="CreateUser.controls.UserName.errors.required">
                                   User Name is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" (change)="clearMsg();" [type]="hide ? 'password' : 'text'" placeholder="Password"
                                formControlName="Password" autocomplete="doNotAutoComplete">
                                <mat-icon  (click)="hide = !hide" style="float: right;margin-top: -33px;
                                margin-right: 10px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <div *ngIf="userSubmitted && CreateUser.controls.Password.invalid">
                                <span class="error-msg" *ngIf="CreateUser.controls.Password.errors.required">
                                    Password is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input class="form-control" (change)="clearMsg();" type="text" placeholder="Email" formControlName="EmailId">
                            <div *ngIf="userSubmitted && CreateUser.controls.EmailId.invalid">
                                <span class="error-msg" *ngIf="CreateUser.controls.EmailId.errors.required">
                                    EmailId is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check sendBOSchkbx">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                formControlName="EmailBos">
                            <label class="form-check-label" for="flexCheckDefault">
                                Send Bill of Sale
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row btn-main-rw">
                    <div class="col-md-12">
                        <div class="form-group">
                            <button type="submit" class="btn btn-fill-out btn-block submitbtn">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="tab-pane" [ngClass]="activeTab == 2? 'active': 'fade'" id="tabs-2" role="tabpanel">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group search-field">
                        <input class="search-inp" matInput (keyup)="applyFilter($event.target.value);"
                            placeholder="Search">
                        <i class="fa fa-search search-icn" aria-hidden="true"></i>
                    </div>
                    <div style="overflow: auto;">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-full-table" id="my-table">

                            <ng-container matColumnDef="Firstname">
                                <th mat-header-cell *matHeaderCellDef class="mat-head-title"> FIRST NAME </th>
                                <td mat-cell *matCellDef="let element "> {{element.FName }} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="LastName">
                                <th mat-header-cell *matHeaderCellDef class="mat-head-title"> LAST NAME </th>
                                <td mat-cell *matCellDef="let element"> {{element.LName }} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="UserName">
                                <th mat-header-cell *matHeaderCellDef class="mat-head-title"> USER NAME </th>
                                <td mat-cell *matCellDef="let element"> {{element.UserName }} </td>
                            </ng-container>
    
                            <ng-container matColumnDef="SendBOS">
                                <th mat-header-cell *matHeaderCellDef class="mat-head-title"> SEND BOS </th>
                                <td mat-cell *matCellDef="let element">
                                    <!--use the below icon for tick symbol-->
                                    <i class="fa fa-check tick-clr" aria-hidden="true" *ngIf="element.EmailBos == '1'"></i>
                                    <!--use the below icon for cross symbol-->
                                    <i class="fa fa-times cross-clr" aria-hidden="true"
                                        *ngIf="element.EmailBos == '0'|| element.EmailBos== null "></i>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="mat-head-title"> ACTIONS </th>
                                <td mat-cell *matCellDef="let element">
                                    <i class="fa fa-pencil space-right" aria-hidden="true" style="cursor: pointer;"
                                        (click)="edituser(element.UserId,dearlerid)"></i>
                                    <i class="fa fa-trash" aria-hidden="true" style="cursor: pointer;"
                                        (click)="Deleteuser(element.UserId)"></i>
                                </td>
                            </ng-container>
    
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" showFirstLastButtons
                        #scheduledOrdersPaginator>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-scroll-up></app-scroll-up>