import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';
import { Location } from '@angular/common';
import { Router } from "@angular/router";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  selectedStatus: any;

  public contactForm: FormGroup;
  contactSubmitted: boolean = false;
  contactUsClicked: boolean = false;

  errorMsg: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  contactUsBtnMessage: any = "Contact Us";

  leadId: any;
  leadDetails: any;
  leadBasicInfo: any;
  leadImages: any = [];
  leadInteriorInfo: any = [];
  leadMechInfo: any = [];
  extra_Info: any = [];
  driverside: any = [];
  frontside: any = [];
  passengerside: any = [];
  rearside: any = [];
  tiresside: any = [];
  exInfoAvailable: boolean = false;
  inInfoAvailable: boolean = false;
  mechInfoAvailable: boolean = false;
  driverAvaliable: boolean = false;
  extraInfoAvailable: boolean = false;
  infoAvailableClass: any;
  frontAvaliable: boolean = false;
  passengerAvaliable: boolean = false;
  rearAvaliable: boolean = false;
  triesAvaliable: boolean = false;
  vehicleLoaded: boolean = false;

  vehicle: any;
  vehicleInfo: any;

  loggedIn: boolean = false;
  offerForm: any;
  offerSubmitted: boolean = false;
  successMsg: any;

  vehicleImages: any = [];
  offerExists: boolean = false;
  offerAmount: any;
  userrole:any;
  driverSideQv: any = [];
  frontSideQv: any = [];
  passengerSideQv: any = [];
  rearSideQv: any = [];
  tireSideQv: any = [];
  extraInfoQv: any = [];
  interiorInfoQv: any = [];
  mechInfoQv: any = [];
  showQuickView: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private db: AngularFireDatabase,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private lightbox: Lightbox,
    private location: Location
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.leadId = params.get('leadId');
      console.log(this.leadId);
    });

    this.offerForm = this.formBuilder.group({
      offerAmount: ['', Validators.required]
    });

    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['Receiving more information'],
    });
  }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    this.selectedStatus = localStorage.getItem("selectedStatus");
    this.userrole =  localStorage.getItem("userrole");
  
      this.getVehicle(this.leadId);
      this.getVehicleDetails(this.leadId);
  

    if (this.loggedIn) {
      this.getPostOfferDetails(this.leadId, localStorage.getItem("userId"));
    }
  }

  getPostOfferDetails(leadId, userId) {
    this.apiService.getPostOfferDetails(leadId, userId).subscribe((resultData) => {
      console.log("Result Data: ", resultData);
      if (resultData['result'] == "1") {
        this.offerExists = true;
        this.offerAmount = resultData['amount'];
      } else {
        this.offerExists = false;
        this.offerAmount = null;
      }
    }, (error) => {
      console.log(error);
    });
  }

  changePostOfferStatus() {
    this.offerExists = false;
    this.successMsg = null;
  }

  getVehicle(leadId: any) {
    var refpath;
    refpath = environment.firebaseRef.vehicles;

 
    this.db.object(refpath + '/' + leadId).snapshotChanges().subscribe((datasnapshot) => {
      this.vehicle = datasnapshot.payload.toJSON();
      this.vehicle['timeAgo'] = new Date(this.vehicle['appraiseAt'] * 1000);
      if (localStorage.getItem("selectedStatus") == "sold") {
        this.vehicle['soldstatus'] = true;
      }
      else {
        this.vehicle['soldstatus'] = false;
      }

    });

  }

  getVehicleDetails(leadId: any) {

    this.db.object(environment.firebaseRef.vehicleInfo + '/' + leadId).snapshotChanges().subscribe((datasnapshot) => {
      this.vehicleInfo = datasnapshot.payload.toJSON();

      this.driverside = [];
      this.frontside = [];
      this.passengerside = [];
      this.rearside = [];
      this.tiresside = [];

      this.leadInteriorInfo = [];
      this.leadMechInfo = [];
      this.leadImages = [];
      this.extra_Info = [];
      if (this.vehicleInfo != null) {
        if (this.vehicleInfo['images'] != null) {
          for (var leadImage in this.vehicleInfo['images']) {
            if (this.vehicleInfo['images'][leadImage]) {
              let splitImage = this.vehicleInfo['images'][leadImage].split("upload");
              if (splitImage.length == 2) {
                this.vehicleInfo['images'][leadImage] = splitImage[0] + 'upload/' + 'l_watermark_logo_1,w_400,h_50,x_0,y_-150/e_auto_brightness/e_sharpen/c_scale,h_400,w_auto,q_100' + splitImage[1];
              }
              if (this.leadImages.length == 0) {
                this.leadImages.push([leadImage, this.vehicleInfo['images'][leadImage], 'active']);
              } else {
                this.leadImages.push([leadImage, this.vehicleInfo['images'][leadImage], '']);
              }
            }
          }
        } else {
          this.leadImages.push(["IM_001", "https://res.cloudinary.com/rck-techiees-pvt-ltd/image/upload/v1612763621/wkdcf8spb9yknezoynbe_rf9a5c.png", "active"]);
        }

        if (this.leadImages.length == 0) {
          this.leadImages.push(["IM_001", "https://res.cloudinary.com/rck-techiees-pvt-ltd/image/upload/v1612763621/wkdcf8spb9yknezoynbe_rf9a5c.png", "active"]);
        }

        this.buildGallery();

        console.log('Images', this.leadImages);
        console.log("this.vehicleInfo['exteriorInfo'].driverside", this.vehicleInfo['exteriorInfo'].driverside)
        var driversideData = Object.values(this.vehicleInfo['exteriorInfo'].driverside);
        var frontData = Object.values(this.vehicleInfo['exteriorInfo'].front);
        var passengerData = Object.values(this.vehicleInfo['exteriorInfo'].passengerside);
        var rearData = Object.values(this.vehicleInfo['exteriorInfo'].rear);
        var tiresData = Object.values(this.vehicleInfo['exteriorInfo'].tires);
        var interiorInfoData = Object.values(this.vehicleInfo['interiorInfo']);
        var mechanicalInfoData = Object.values(this.vehicleInfo['mechanicalInfo']);
        // var extraInfoData = Object.values(this.vehicleInfo['extraInfo']);
        console.log(this.vehicleInfo['extraInfo']);
        // console.log(extraInfoData);
        if (this.vehicleInfo['exteriorInfo']) {
          console.log(this.vehicleInfo['exteriorInfo'])
          this.exInfoAvailable = true;
        }
        console.log(driversideData);
        console.log(frontData);
        console.log(passengerData);
        console.log(interiorInfoData);
        console.log(mechanicalInfoData);
        driversideData.forEach(element => {
          if (element) {
            this.driverAvaliable = true;
          }
          console.log("this.element", element);
          this.driverside.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let driversideInfo of this.driverside) {
          if (driversideInfo.value) {
            this.driverAvaliable = true;
          }
          else {
            this.driverAvaliable = false;
          }

        }
        frontData.forEach(element => {
          if (element) {
            this.frontAvaliable = true;
          }
          console.log("this.element", element);
          this.frontside.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let frontsideInfo of this.frontside) {
          if (frontsideInfo.value) {
            this.frontAvaliable = true;
          }
          else {
            this.frontAvaliable = false;
          }

        }
        passengerData.forEach(element => {
          if (element) {
            this.passengerAvaliable = true;
          }
          console.log("this.element", element);
          this.passengerside.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let passengersideInfo of this.passengerside) {
          if (passengersideInfo.value) {
            this.passengerAvaliable = true;
          }
          else {
            this.passengerAvaliable = false;
          }

        }

        rearData.forEach(element => {
          if (element) {
            this.rearAvaliable = true;
          }
          console.log("this.element", element);
          this.rearside.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let rearsideInfo of this.rearside) {
          if (rearsideInfo.value) {
            this.rearAvaliable = true;
          }
          else {
            console.log(this.rearAvaliable);
            this.rearAvaliable = false;
          }
          console.log(this.rearAvaliable);
        }

        tiresData.forEach(element => {
          if (element) {
            this.triesAvaliable = true;
          }
          console.log("this.element", element);
          this.tiresside.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let tiressideInfo of this.tiresside) {
          if (tiressideInfo.value) {
            this.triesAvaliable = true;
          }
          else {
            console.log(this.triesAvaliable);
            this.triesAvaliable = false;
          }
          console.log(this.triesAvaliable);
        }

        //  tiresside
        console.log("this.rearside", this.rearside);
        interiorInfoData.forEach(element => {
          if (element) {
            this.inInfoAvailable = true;
          }
          console.log("this.element", element);
          this.leadInteriorInfo.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        // 
        for (var extra in this.vehicleInfo['extraInfo']) {
          console.log(this.vehicleInfo['extraInfo'][extra]);
          if (this.vehicleInfo['extraInfo'][extra]) {
            this.extraInfoAvailable = true;
          }
          console.log(extra[0], extra[1]);
          this.extra_Info.push([extra, this.vehicleInfo['extraInfo'][extra]]);
          console.log(this.extra_Info);
        }


        mechanicalInfoData.forEach(element => {
          if (element) {
            this.mechInfoAvailable = true;
          }
          console.log("this.element", element);
          this.leadMechInfo.push({
            "key": element["Key"],
            "value": element["Value"]
          });

        });
        for (let leadMechInfoside of this.leadMechInfo) {
          if (leadMechInfoside.value) {
            this.mechInfoAvailable = true;
          }
          else {
            this.mechInfoAvailable = false;
          }

        }
        // for(var mechInfo in this.vehicleInfo['mechanicalInfo']) {
        //   console.log("mechInfo",mechInfo);
        //   if(this.vehicleInfo['mechanicalInfo'][mechInfo]) {
        //     this.mechInfoAvailable = true;
        //   }
        //   this.leadMechInfo.push([mechInfo, this.vehicleInfo['mechanicalInfo'][mechInfo]]);
        //   console.log("leadMechInfo",this.leadMechInfo);
        // }

        this.checkInfoAvailability();
      } else {
        this.leadImages.push(["IM_001", "https://res.cloudinary.com/rck-techiees-pvt-ltd/image/upload/v1612763621/wkdcf8spb9yknezoynbe_rf9a5c.png", "active"]);
      }

      this.vehicleLoaded = true;
      this.toggleQuickView();
    });
  }

  toggleQuickView() {
    this.driverSideQv = [];
    this.frontSideQv = [];
    this.passengerSideQv = [];
    this.rearSideQv = [];
    this.tireSideQv = [];
    this.extraInfoQv = [];
    this.interiorInfoQv = [];
    this.mechInfoQv = [];

    console.log("***driverside", this.driverside.length);
    if (this.driverside.length > 0) {
      for (let driversideInfo of this.driverside) {
        if (driversideInfo.value != 'GOOD CONDITION' && driversideInfo.value != '') {
          this.driverSideQv.push({
            "key": driversideInfo.key,
            "value": driversideInfo.value
          });
        }
      }
    }

    console.log("***frontside", this.frontside.length);
    if (this.frontside.length > 0) {
      for (let frontsideInfo of this.frontside) {
        if (frontsideInfo.value != 'GOOD CONDITION' && frontsideInfo.value != '') {
          this.frontSideQv.push({
            "key": frontsideInfo.key,
            "value": frontsideInfo.value
          });
        }
      }
    }

    console.log("***passengerside", this.passengerside.length);
    if (this.passengerside.length > 0) {
      for (let passengersideInfo of this.passengerside) {
        if (passengersideInfo.value != 'GOOD CONDITION' && passengersideInfo.value != '') {
          this.passengerSideQv.push({
            "key": passengersideInfo.key,
            "value": passengersideInfo.value
          });
        }
      }
    }

    console.log("***rearside", this.rearside.length);
    if (this.rearside.length > 0) {
      for (let rearsideInfo of this.rearside) {
        if (rearsideInfo.value != 'GOOD CONDITION' && rearsideInfo.value != '') {
          this.rearSideQv.push({
            "key": rearsideInfo.key,
            "value": rearsideInfo.value
          });
        }
      }
    }

    console.log("***tiresside", this.tiresside.length);
    if (this.tiresside.length > 0) {
      for (let tiressideInfo of this.tiresside) {
        if (tiressideInfo.value != 'GOOD CONDITION' && tiressideInfo.value != '') {
          this.tireSideQv.push({
            "key": tiressideInfo.key,
            "value": tiressideInfo.value
          });
        }
      }
    }

    console.log("***extra_Info", this.extra_Info.length);
    if (this.extra_Info.length > 0) {
      for (let ExtraInfo of this.extra_Info) {
        if (ExtraInfo[1] != "") {
          this.extraInfoQv.push({
            "key": ExtraInfo[0],
            "value": ExtraInfo[1]
          });
        }
      }
    }

    console.log("***leadInteriorInfo", this.leadInteriorInfo.length);
    if (this.leadInteriorInfo.length > 0) {
      for (let interiorInfo of this.leadInteriorInfo) {
        if (interiorInfo.value != 'GOOD CONDITION' && interiorInfo.value != '' && interiorInfo.value != 'YES') {
          this.interiorInfoQv.push({
            "key": interiorInfo.key,
            "value": interiorInfo.value
          });
        }
      }
    }

    console.log("***leadMechInfo", this.leadMechInfo.length);
    if (this.leadMechInfo.length > 0) {
      for (let mechInfo of this.leadMechInfo) {
        if (mechInfo.value != 'GOOD CONDITION' && mechInfo.value != '') {
          this.mechInfoQv.push({
            "key": mechInfo.key,
            "value": mechInfo.value
          });
        }
      }
    }

    if (this.driverSideQv.length > 0 || this.frontSideQv.length > 0 || this.passengerSideQv.length > 0 || this.rearSideQv.length > 0 || this.tireSideQv.length > 0 || this.extraInfoQv.length > 0 || this.interiorInfoQv.length > 0 || this.mechInfoQv.length > 0) {
      this.showQuickView = true;
    } else {
      this.showQuickView = false;
    }
  }

  checkInfoAvailability() {
    if (this.inInfoAvailable && this.exInfoAvailable && this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-4';
    } else if (this.inInfoAvailable && this.exInfoAvailable && !this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-6';
    } else if (this.inInfoAvailable && !this.exInfoAvailable && this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-6';
    } else if (!this.inInfoAvailable && this.exInfoAvailable && this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-6';
    } else if (this.inInfoAvailable && !this.exInfoAvailable && !this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-12';
    } else if (!this.inInfoAvailable && !this.exInfoAvailable && this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-12';
    } else if (!this.inInfoAvailable && this.exInfoAvailable && !this.mechInfoAvailable) {
      this.infoAvailableClass = 'col-md-12';
    } else {
      this.infoAvailableClass = 'col-md-4';
    }
  }

  saveOfferAmount() {
    this.offerSubmitted = true;
    if (this.offerForm.invalid) {
      this.successMsg = null;
      return;
    }


    console.log(this.offerForm);
    let data = {
      "user_id": localStorage.getItem("userId"),
      "leadid": this.leadId,
      "offer_amount": this.offerForm.value.offerAmount,
      "notes_from": "4",
      "device_id": "browser"
    };

    this.apiService.postOfferAmount(data).subscribe((resultData) => {
      console.log("ResultData: ", resultData);
      if (resultData == 1) {
        console.log('Offer amount saved successfully');
        this.offerAmount = data['offer_amount'];
        this.offerForm.controls['offerAmount'].setValue(null);
        this.offerSubmitted = false;
        this.successMsg = "Offer amount saved successfully";
        this.offerExists = true;
      } else {
        this.successMsg = null;
        console.log('Something went wrong. Try again!');
      }
    }, (error) => {
      this.successMsg = null;
      console.log(error);
      console.log('Something went wrong. Try again!');
    });
  }

  clearMsg() {
    this.successMsg = null;
    this.offerSubmitted = false;
  }


  sendInterestedMessage() {
    console.log(this.contactForm);
    this.contactSubmitted = true;

    if (this.contactForm.invalid) {
      return;
    }
    console.log(this.vehicle);
    this.contactUsBtnMessage = "Sending Message...";
    let data = {
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      phone: this.contactForm.value.phone,
      email: this.contactForm.value.email,
      interested: this.contactForm.value.message,
      leadid: this.leadId,
      year: this.vehicle['year'] != null ? this.vehicle['year'] : '',
      make: this.vehicle['make'] != null ? this.vehicle['make'] : '',
      model: this.vehicle['model'] != null ? this.vehicle['model'] : '',
      trim: this.vehicle['style'] != null ? this.vehicle['model'] : '',
    };
    console.log("data", data);

    this.contactUsClicked = true;
    this.apiService.sendInterestedMessage(data).subscribe((resultData) => {
      console.log("ResultData: ", resultData);
      this.contactUsClicked = false;
      this.contactUsBtnMessage = "Contact Us";
      if (resultData['status'] == "1") {
        console.log('Message sent successfully');
        this.resetContactForm();
        this.contactSubmitted = false;
        this.successMsg = "Message sent successfully";
      } else {
        this.successMsg = null;
        this.errorMsg = "Something went wrong. Try again!";
        console.log('Something went wrong. Try again!');
      }
    }, (error) => {
      this.contactUsBtnMessage = "Contact Us";
      this.contactUsClicked = false;
      this.successMsg = null;
      this.errorMsg = "Something went wrong. Try again!";
      console.log(error);
      console.log('Something went wrong. Try again!');
    });
  }

  resetContactForm() {
    this.contactForm.controls['firstName'].setValue(null);
    this.contactForm.controls['lastName'].setValue(null);
    this.contactForm.controls['phone'].setValue(null);
    this.contactForm.controls['email'].setValue(null);
    this.contactForm.controls['message'].setValue("Receiving more information");
  }

  copyVin(vin) {
    console.log("VIN: ", vin);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = vin;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toastr.success("VIN is copied", null, {
      timeOut: 3000
    });
  }

  buildGallery() {
    this.leadImages.forEach(leadImage => {
      let updatedImage = leadImage[1].replace("h_400", "h_800");
      const vehicleImage = {
        src: updatedImage,
        caption: "",
        thumb: leadImage[1]
      };
      this.vehicleImages.push(vehicleImage);
    });
  }

  openGallery(index: number) {
    this.lightbox.open(this.vehicleImages, index, {
      fitImageInViewPort: true,
      showImageNumberLabel: true,
      wrapAround: true,
      alwaysShowNavOnTouchDevices: true,
      centerVertically: true,
      enableTransition: true,
    });
  }
   navToHome() {
 
    console.log("Testing back");
    localStorage.setItem("backtohome", "true");       
   // this.router.navigateByUrl("/inventory");
    window.location.href="/inventory";
  }

  // navToHome() {
  //   localStorage.setItem("backtohome", "true");
  //   this.location.back();

  // }
}
