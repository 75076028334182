import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
} from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { environment } from "src/environments/environment";

import * as _ from "lodash";
import { AuthService } from "../services/auth.service";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../services/window.service";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit {
  showMyFilters: boolean = false;
  userId: any;
  offset: any;
  loggedIn: boolean = false;
  vehiclesLoaded: boolean = false;
  allVehiclesLoaded: boolean = false;
  finished: boolean = true;
  batchSize: number = 50;

  vehicles: any = [];
  allVehicles: any = [];
  makeList: any = [];
  modelList: any = [];
  minYearList: any = [];
  maxYearList: any = [];

  savedStatus: any = null;
  selectedMake: any = null;
  selectedModel: any = null;
  selectedMinYear: any = null;
  selectedMaxYear: any = null;
  selectedStatus: any = null;
  selectedOdoMin: any = null;
  selectedOdoMax: any = null;
  selectedAutocheck: any = null;
  selectedCarfax: any = null;
  selectedAccidents: any = null;
  // Status_sold: boolean = false;
  filteredCarCount: any = null;
  filterOn: boolean = false;
  filterOptions: any;
  searchModel: any;
  AutoCheck_value: any = [];
  odometer_value: any = [];
  autoaccident_value: any = [];
  carfax_value: any = [];
  myFilters: any = [];
  selectedFilterId: any;

  regExp: any = /[a-zA-Z]/g;
  @ViewChild("minYearIp") minYearIp: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private scrollToService: ScrollToService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    this.userId = localStorage.getItem("userId");
    this.scrollToPosition();
    this.getInitialFilterValues();
    // console.log("reload");
    this.filterStatus(this.selectedStatus);
    this.getAllVehicles(this.selectedStatus);
    this.populateFilters();

    if (this.loggedIn && this.userId != null) {
      this.getMyFilters(localStorage.getItem("userId"), 0);
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll(): void {
    this.offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    localStorage.setItem("scrollPosition", this.offset);
  }

  showMyFiltersList() {
    if (this.showMyFilters) {
      this.hideMyFiltersList();
    } else {
      this.showMyFilters = true;
      this.selectedStatus = "myfilter";
    }
  }

  hideMyFiltersList() {
    this.showMyFilters = false;
  }

  scrollToPosition(): void {
    if (localStorage.getItem("scrollPosition")) {
      const offset = (localStorage.getItem(
        "scrollPosition"
      ) as unknown) as number;
      const config: ScrollToConfigOptions = {
        offset,
      };

      this.scrollToService.scrollTo(config);
    }
  }

  getInitialFilterValues(): void {
    this.selectedMake = localStorage.getItem("selectedMake")
      ? localStorage.getItem("selectedMake")
      : null;
    this.selectedModel = localStorage.getItem("selectedModel")
      ? localStorage.getItem("selectedModel")
      : null;
    this.selectedMinYear = localStorage.getItem("selectedMinYear")
      ? localStorage.getItem("selectedMinYear")
      : null;
    this.selectedMaxYear = localStorage.getItem("selectedMaxYear")
      ? localStorage.getItem("selectedMaxYear")
      : null;
    this.selectedStatus = localStorage.getItem("selectedStatus")
      ? localStorage.getItem("selectedStatus")
      : "all";
  }

  filterStatus(selectedStatus: string): void {
    console.log("selected filtert " , selectedStatus)
    if (this.selectedStatus == "myfilter") {
      this.clearFilters();
    }
    this.hideMyFiltersList();
    this.vehiclesLoaded = false;
    this.highlightActiveStatus(selectedStatus);
    this.getAllVehicles(this.selectedStatus);
    this.applyFilter();
    this.populateFilters();
  }

  clearFilters() {
    this.selectedStatus = null;
    this.selectedMake = null;
    this.selectedModel = null;
    this.selectedMaxYear = null;
    this.selectedMinYear = null;
    this.selectedOdoMax = null;
    this.selectedOdoMin = null;
    this.savedStatus = null;
    this.selectedAccidents = null;
    this.selectedCarfax = null;
    this.selectedAutocheck = null;
  }

  getAllVehicles(selectedStatus: string) {

    var refPath;
    // console.log(selectedStatus);
    refPath = environment.firebaseRef.vehicles;
    // if (selectedStatus == "sold") {
    //   refPath = environment.firebaseRef.soldvehicles;
    // }
    // else {
    //   refPath = environment.firebaseRef.vehicles;
    // }
    // console.log(refPath);
    this.db
      .list(refPath, (ref) =>
        ref.orderByChild(this.getOrderByTag(selectedStatus))
      )
      .valueChanges()
      .subscribe((datasnapshot) => {
        this.allVehicles = [];
        var localVehicles = [];
        datasnapshot.reverse().forEach((snapshot) => {
          localVehicles.push(this.getVehicle(snapshot));
        });

        if (this.selectedStatus == "in-progress") {
          localVehicles = _.filter(localVehicles, function (vehicle: any) {
            return vehicle["status"] == 1;
          });
        } else if (this.selectedStatus == "inventory") {
          localVehicles = _.filter(localVehicles, function (vehicle: any) {
            return vehicle["status"] == 2;
          });
        } else if (this.selectedStatus == "sold") {
          localVehicles = _.filter(localVehicles, function (vehicle: any) {
            return vehicle["status"] == 3;
          });
        }

        // console.log("All Vehicles Length: ", localVehicles.length);
        this.allVehicles = localVehicles;
        this.allVehiclesLoaded = true;
        if (localStorage.getItem("selectid") != null) {
          this.getMyFilterData(localStorage.getItem("selectid"));
        } else {
          this.getVehicles(this.selectedStatus);
        }
      });
  }

  getVehicles(selectedStatus: string) {
    // console.log("this.filterOn", this.filterOn);

    if (this.filterOn) {
      this.vehicles = [];
      this.vehiclesLoaded = false;
      // console.log(this.allVehicles, this.filterOptions);
      this.beginFilterVehicles(this.allVehicles, this.filterOptions);

      this.vehiclesLoaded = true;
      this.finished = true;
    } else {
      var refPath;
      refPath = environment.firebaseRef.vehicles;
      // if (selectedStatus == "sold") {
      //   refPath = environment.firebaseRef.soldvehicles;
      // }
      // else {
      //   refPath = environment.firebaseRef.vehicles;
      // }
      if (this.selectedStatus == "sold") {
        this.db
          .list(refPath, (ref) =>
            ref
              .orderByChild(this.getOrderByTag(selectedStatus))
            // .limitToLast(this.batchSize)
          )
          .valueChanges()
          .subscribe((datasnapshot) => {
            this.vehicles = [];
            var localVehicles = [];
            datasnapshot.reverse().forEach((snapshot) => {
              localVehicles.push(this.getVehicle(snapshot));
            });

            if (this.selectedStatus == "in-progress") {
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 1;
              });
            } else if (this.selectedStatus == "inventory") {
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 2;
              });
            } else if (this.selectedStatus == "sold") {
              // console.log("Testing sold");
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 3;
              });
            }
            // console.log(localVehicles.length);
            if (this.vehicles.length == 0) {
              this.vehicles = localVehicles;
            } else {
              var vehiclesLength = this.vehicles.length;
              var newVehiclesCount = localVehicles.length - this.vehicles.length;
              for (let index = 0; index < newVehiclesCount; index++) {
                this.vehicles[index + vehiclesLength] =
                  localVehicles[index + vehiclesLength];
              }
            }

            // console.log("Vehicles Count: ", this.vehicles.length);
            this.vehiclesLoaded = true;
            this.finished = true;
            this.filteredCarCount = null;
          });
      }
      else {
        this.db
          .list(refPath, (ref) =>
            ref
              .orderByChild(this.getOrderByTag(selectedStatus))
              .limitToLast(this.batchSize)
          )
          .valueChanges()
          .subscribe((datasnapshot) => {
            this.vehicles = [];
            var localVehicles = [];
            datasnapshot.reverse().forEach((snapshot) => {
              localVehicles.push(this.getVehicle(snapshot));
            });

            if (this.selectedStatus == "in-progress") {
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 1;
              });
            } else if (this.selectedStatus == "inventory") {
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 2;
              });
            } else if (this.selectedStatus == "sold") {
              // console.log("Testing sold");
              localVehicles = _.filter(localVehicles, function (vehicle: any) {
                return vehicle["status"] == 3;
              });
            }
            // console.log(localVehicles.length);
            if (this.vehicles.length == 0) {
              this.vehicles = localVehicles;
            } else {
              var vehiclesLength = this.vehicles.length;
              var newVehiclesCount = localVehicles.length - this.vehicles.length;
              for (let index = 0; index < newVehiclesCount; index++) {
                this.vehicles[index + vehiclesLength] =
                  localVehicles[index + vehiclesLength];
              }
            }

            // console.log("Vehicles Count: ", this.vehicles.length);
            this.vehiclesLoaded = true;
            this.finished = true;
            this.filteredCarCount = null;
          });
      }
    }
  }

  getOrderByTag(selectedStatus: string): string {
    let orderByTag: string = "orderAt";

    switch (selectedStatus) {
      case "in-progress":
        orderByTag = "appraiseAt";
        break;
      case "inventory":
        orderByTag = "purchaseAt";
        break;
      default:
        orderByTag = "orderAt";
        break;
    }

    return orderByTag;
  }

  getVehicle(snapshot: any): any {
    const vehicle = snapshot;
// console.log("vehi....",vehicle["previewImage"]);
    if (vehicle["previewImage"]) {
      let splitImage = vehicle["previewImage"].split("upload");
      if (splitImage.length == 2) {
        if (vehicle["previewImage"].includes(vehicle["leadId"])) {
          vehicle["previewImage"] =
            splitImage[0] +
            "upload/" +
            "c_scale,h_250,w_300,q_100/" +
            "l_watermark_logo_1,w_100,h_20,x_90,y_-110/" +
            "e_sharpen/e_auto_brightness" +
            splitImage[1];
        }
      }
    }

    return vehicle;
  }

  populateFilters(): void {
    this.getMakeList();
    this.getModelList();
    this.getMinYearList();
    this.getMaxYearList();
  }

  getMakeList() {
    var refpath;
    refpath = environment.firebaseRef.makeList;
    // if (this.selectedStatus == "sold") {
    //   refpath = environment.firebaseRef.soldmake;
    // } else {
    //   refpath = environment.firebaseRef.makeList;
    // }
    this.db
      .list(refpath)
      .snapshotChanges()
      .subscribe((datasnapshot) => {
        this.makeList = [];
        datasnapshot.forEach((snapshot) => {
          this.makeList.push(this.getCountData(snapshot));
        });
      });
  }

  getModelList(selectedMake: any = null) {
    var refpath;
    refpath = environment.firebaseRef.modelList;
    if (this.selectedStatus == "sold") {
      refpath = environment.firebaseRef.soldmodel;
    } else {
      refpath = environment.firebaseRef.modelList;
    }
    if (selectedMake == null || selectedMake == "") { 
      // console.log("refpath",refpath);
      this.db     
        .list(refpath)
        .snapshotChanges()
        .subscribe((datasnapshot) => {
          this.modelList = [];
          // console.log("snap",datasnapshot);
          // datasnapshot.forEach((snapshot) => {
          //   this.modelList.push(this.getCountData(snapshot));
          // });
        });
    } else {
      this.db
        .list(refpath, (modelRef) =>
          modelRef.orderByChild("make").equalTo(selectedMake)
        )
        .snapshotChanges()
        .subscribe((datasnapshot) => {
          this.modelList = [];
          datasnapshot.forEach((snapshot) => {
            this.modelList.push(this.getCountData(snapshot));
          });
        });
    }
  }

  getCountData(snapshot: any): any {
    const data = snapshot.payload.toJSON();
    data["id"] = snapshot.key;

    switch (this.selectedStatus) {
      case "in-progress":
        data["count"] = data["count"]["Progress"];
        break;
      case "inventory":
        data["count"] = data["count"]["inventory"];
        break;
      default:
        data["count"] = data["count"]["All"];
        break;
    }

    return data;
  }

  getMinYearList(): void {
    this.minYearList = [];
    const minYr = 1974;
    const maxYr = new Date().getFullYear() + 1;
    // for (let minYear = minYr; minYear <= maxYr; minYear++) {
    //   this.minYearList.push(minYear);
    // }

    for (let maxYear = maxYr; maxYear >= minYr; maxYear--) {
      this.minYearList.push(maxYear);
    }
  }

  getMaxYearList(selectedMinYear: any = null): void {
    this.maxYearList = [];
    let maxYr = new Date().getFullYear() + 1;
    let minYr = 1974;
    if (selectedMinYear != null && selectedMinYear != "") {
      maxYr = new Date().getFullYear() + 1;
      minYr = selectedMinYear;
    }

    for (let maxYear = maxYr; maxYear >= minYr; maxYear--) {
      this.maxYearList.push(maxYear);
    }
  }

  onScroll() {
    if (!this.filterOn) {
      this.batchSize = this.batchSize + 15;
      if (this.vehicles.length > 0) {
        this.finished = false;
      }

      if (this.searchModel == null || this.searchModel == "") {
        this.getVehicles(this.selectedStatus);
      } else {
        this.finished = true;
      }
    }
  }

  highlightActiveStatus(selectedStatus: string): void {
    localStorage.setItem("selectedStatus", selectedStatus);
    this.selectedStatus = selectedStatus;
    // console.log("Selected Status: ", this.selectedStatus);
  }

  onMakeChange(event: any) {
    localStorage.setItem("selectedMake", event.target.value);
    this.selectedModel = null;
    const selectedMake = event.target.value;
    if (selectedMake) {
      this.selectedMake = selectedMake;
      this.getModelList(selectedMake);
    } else {
      this.selectedMake = null;
      this.getModelList();
    }
    this.applyFilter();
    this.getVehicles(this.selectedStatus);
  }

  // onModelChange(event: any) {
  //   localStorage.setItem("selectedModel", event.target.value);
  //   // this.selectedModel = null;
  //   const selectedModel = event.target.value;
  //   if (selectedModel) {
  //     this.selectedModel = selectedModel;
  //     this.getModelList(selectedModel);
  //   } else {
  //     this.selectedModel = null;
  //     this.getModelList();
  //   }
  //   this.applyFilter();
  //   this.getVehicles(this.selectedStatus);   
  // }

  onModelChange(event: any) {
    localStorage.setItem("selectedModel", event.target.value);
    const selectedModel = event.target.value;
    if (selectedModel) {
      this.selectedModel = selectedModel;
    } else {
      this.selectedModel = null;
    }
    this.applyFilter();
    this.getVehicles(this.selectedStatus);
  }

  onMinYearChange(event: any) {
    localStorage.setItem("selectedMinYear", event.target.value);
    const selectedMinYear = event.target.value;
    if (selectedMinYear) {
      this.selectedMinYear = selectedMinYear;
      this.getMaxYearList(selectedMinYear);
    } else {
      this.selectedMinYear = null;
    }
    this.applyFilter();
    this.getVehicles(this.selectedStatus);
  }

  onMaxYearChange(event: any) {
    localStorage.setItem("selectedMaxYear", event.target.value);
    const selectedMaxYear = event.target.value;
    if (selectedMaxYear) {
      this.selectedMaxYear = selectedMaxYear;
    } else {
      this.selectedMaxYear = null;
    }
    this.applyFilter();
    this.getVehicles(this.selectedStatus);
  }

  applyFilter() {
    this.filterOptions = {
      make: this.selectedMake,
      model: this.selectedModel,
      minYear: this.selectedMinYear,
      maxYear: this.selectedMaxYear,
      odoMin: this.selectedOdoMin,
      odoMax: this.selectedOdoMax,
      accidents: this.selectedAccidents,
      autoCheck: this.selectedAutocheck,
      carFax: this.selectedCarfax,
    };

    // console.log("Filter Options: ", this.filterOptions);

    if (
      this.selectedMake == null &&
      this.selectedModel == null &&
      this.selectedMinYear == null &&
      this.selectedMaxYear == null &&
      this.selectedOdoMin == null &&
      this.selectedOdoMax == null &&
      this.selectedAccidents == null &&
      this.selectedAutocheck == null &&
      this.selectedCarfax == null
    ) {
      this.filterOn = false;
      if (this.loggedIn) {
        this.selectedFilterId = null;
      }
    } else {
      this.filterOn = true;
    }
  }

  beginFilterVehicles(allVehicles, filterOptions: any) {
    this.vehicles=[];
    // console.log(filterOptions);
    let filteredVehicles: any = [];
    if (filterOptions.model != null && filterOptions.model != "") {
      // Filtering vehicles by model
      filteredVehicles = _.filter(allVehicles, function (vehicle: any) {
        return vehicle["model"] == filterOptions.model;
      });
    }
    else {
      filteredVehicles = allVehicles;
    }
    if (filterOptions.make != null && filterOptions.make != "") {
      // Filtering vehicles by make
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        return vehicle["make"] == filterOptions.make;
      });
    }
    else {
      // filteredVehicles = allVehicles;
    }

    if (
      filterOptions.minYear != null &&
      filterOptions.minYear != "" &&
      filterOptions.maxYear != null &&
      filterOptions.maxYear != ""
    ) {
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        return (
          vehicle["year"] >= filterOptions.minYear &&
          vehicle["year"] <= filterOptions.maxYear
        );
      });
    } else {
      if (filterOptions.minYear != null && filterOptions.minYear != "") {
        filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
          return vehicle["year"] >= filterOptions.minYear;
        });
      } else if (filterOptions.maxYear != null && filterOptions.maxYear != "") {
        filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
          return vehicle["year"] <= filterOptions.maxYear;
        });
      }
    }
    if (filterOptions.autoCheck != null && filterOptions.autoCheck != "") {
      // console.log(filterOptions.autoCheck);
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        // console.log(vehicle["autoCheck"], filterOptions.autoCheck);
        return vehicle["autoCheck"] == filterOptions.autoCheck;
      });
    }
    if (filterOptions.accidents != null && filterOptions.accidents != "") {
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        return vehicle["accidents"] == filterOptions.accidents;
      });
    }
    if (filterOptions.carFax != null && filterOptions.carFax != "") {
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        return vehicle["carFax"] == filterOptions.carFax;
      });
    }

    if (
      filterOptions.odoMin != null &&
      filterOptions.odoMin != "" &&
      filterOptions.odoMax != null &&
      filterOptions.odoMax != ""
    ) {
      filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
        return (
          parseInt(vehicle["mileage"]) >= parseInt(filterOptions.odoMin) &&
          parseInt(vehicle["mileage"]) <= parseInt(filterOptions.odoMax)
        );
      });
    } else {
      if (filterOptions.odoMin != null && filterOptions.odoMin != "") {
        filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
          return vehicle["mileage"] >= filterOptions.odoMin;
        });
      } else if (filterOptions.odoMax != null && filterOptions.odoMax != "") {
        filteredVehicles = _.filter(filteredVehicles, function (vehicle: any) {
          return vehicle["mileage"] <= filterOptions.odoMax;
        });
      }
    }
    this.vehicles = filteredVehicles;
    this.filteredCarCount = this.vehicles.length;
  }

  searchLead() {
    if (this.regExp.test(this.searchModel)) {
      this.vehiclesLoaded = false;
      if (this.searchModel) {
        this.searchLeadByVin();
      } else {
        this.vehiclesLoaded = true;
      }
    } else {
      this.vehiclesLoaded = false;
      if (this.searchModel) {
        this.searchLeadById();
      } else {
        this.vehiclesLoaded = true;
      }
    }
  }

  searchLeadByVin() {
    var refPath;
    refPath = environment.firebaseRef.vehicles;
    // if (localStorage.getItem("selectedStatus") == "sold") {
    //   refPath = environment.firebaseRef.soldvehicles;
    // }
    // else {
    //   refPath = environment.firebaseRef.vehicles;
    // }
    this.db
      .list(refPath, (vehicleRef) =>
        vehicleRef
          .orderByChild("vin")
          .equalTo(this.searchModel.toString().trim())
      )
      .snapshotChanges()
      .subscribe((datasnapshot) => {
        this.vehicles = [];
        // console.log("datasnapshot vin" + datasnapshot.length);
        if (datasnapshot.length == 0) {
          this.searchLeadByPartialVin();
        } else if (datasnapshot.length == 1) {
          this.updateVehiclesList(datasnapshot);
        } else {
          this.db
            .list(refPath, (vehicleRef) =>
              vehicleRef
                .orderByChild("vin")
                .startAt(this.searchModel.toString().trim())
            )
            .snapshotChanges()
            .subscribe((datasnapshot) => {
              this.vehicles = [];
              this.updateVehiclesList(datasnapshot);
            });
        }
      });
  }

  searchLeadById() {
    var refPath;
    refPath = environment.firebaseRef.vehicles;
    // if (localStorage.getItem("selectedStatus") == "sold") {
    //   refPath = environment.firebaseRef.soldvehicles;
    // }
    // else {
    //   refPath = environment.firebaseRef.vehicles;
    // }
    this.db
      .list(refPath, (vehicleRef) =>
        vehicleRef
          .orderByChild("leadId")
          .equalTo(this.searchModel.toString().trim())
      )
      .snapshotChanges()
      .subscribe((datasnapshot) => {
        this.vehicles = [];

        if (datasnapshot.length == 0) {
          this.searchLeadByPartialVin();
        } else if (datasnapshot.length == 1) {
          this.updateVehiclesList(datasnapshot);
        } else {
          this.db
            .list(refPath, (vehicleRef) =>
              vehicleRef.orderByChild("leadId").startAt(this.searchModel)
            )
            .snapshotChanges()
            .subscribe((datasnapshot) => {
              this.vehicles = [];
              this.updateVehiclesList(datasnapshot);
            });
        }
      });
  }

  searchLeadByPartialVin() {
    var refPath;
    refPath = environment.firebaseRef.vehicles;
    // if (localStorage.getItem("selectedStatus") == "sold") {
    //   refPath = environment.firebaseRef.soldvehicles;
    // }
    // else {
    //   refPath = environment.firebaseRef.vehicles;
    // }
    this.db
      .list(refPath, (vehicleRef) =>
        vehicleRef
          .orderByChild("partialVin")
          .equalTo(this.searchModel.toString().trim())
      )
      .snapshotChanges()
      .subscribe((datasnapshot) => {
        this.vehicles = [];
        // console.log("datasnapshot partialVin" + datasnapshot.length);
        if (datasnapshot.length == 1) {
          this.updateVehiclesList(datasnapshot);
        } else {
          this.db
            .list(refPath, (vehicleRef) =>
              vehicleRef
                .orderByChild("partialVin")
                .startAt(this.searchModel)
                .endAt(this.searchModel)
            )
            .snapshotChanges()
            .subscribe((datasnapshot) => {
              this.vehicles = [];
              this.updateVehiclesList(datasnapshot);
            });
        }
      });
  }

  updateVehiclesList(datasnapshot: any) {
    datasnapshot.forEach((snapshot) => {
      // console.log(snapshot);
      const vehicle = snapshot.payload.toJSON();
      if (vehicle["previewImage"]) {
        let splitImage = vehicle["previewImage"].split("upload");
        if (splitImage.length == 2) {
          if (vehicle["previewImage"].includes(vehicle["leadId"])) {
            vehicle["previewImage"] =
              splitImage[0] +
              "upload/" +
              "c_scale,h_250,w_300,q_100/" +
              "l_watermark_logo_1,w_100,h_20,x_90,y_-110/" +
              "e_sharpen/e_auto_brightness" +
              splitImage[1];
          }
        }
      }
      this.vehicles.push(vehicle);
    });
    this.vehiclesLoaded = true;
  }

  removeOption(option: string) {
    switch (option) {
      case "status":
        this.savedStatus = null;
      localStorage.removeItem("selectedStatus");
      case "make":
        this.selectedMake = null;
        // this.selectedModel = null;
        localStorage.removeItem("selectedMake");
        this.getModelList();
        this.applyFilter();
        this.getVehicles(this.selectedStatus);
        break;
      case "model":
        this.selectedModel = null;
        localStorage.removeItem("selectedModel");
        this.applyFilter();
        this.getVehicles(this.selectedStatus);
        break;
      case "minYear":
        this.selectedMinYear = null;
        localStorage.removeItem("selectedMinYear");
        this.applyFilter();
        this.getVehicles(this.selectedStatus);
        this.getMaxYearList();
        // this.getMaxYearList();
        break;
      case "maxYear":
        this.selectedMaxYear = null;
        localStorage.removeItem("selectedMaxYear");
        this.applyFilter();
        this.getVehicles(this.selectedStatus);
        break;
      case "odoMin":
        this.selectedOdoMin = null;
        break;
      case "odoMax":
        this.selectedOdoMax = null;
        break;
      case "autocheck":
        this.selectedAutocheck = null;
        break;
      case "accident":
        this.selectedAccidents = null;
        break;
      case "carfax":
        this.selectedCarfax = null;
        break;
    }

    this.applyFilter();
    this.getVehicles(this.selectedStatus);
  }
  removefilter() {
    this.selectedFilterId = null;
    this.selectedMake = null;
    this.selectedModel = null;
    this.selectedMinYear = null;
    this.selectedMaxYear = null;
    this.selectedOdoMin = null;
    this.selectedOdoMax = null;
    this.selectedAccidents = null;
    this.selectedCarfax = null;
    this.selectedAutocheck = null;
    // this.savedStatus = null;
    // console.log(this.selectedMake);
    localStorage.removeItem("selectedMake");
    localStorage.removeItem("selectedModel");
    localStorage.removeItem("selectedMinYear");
    localStorage.removeItem("selectedMaxYear");
    localStorage.removeItem("selectid");
    // console.log(this.selectedMake);
    // console.log(this.selectedStatus);
    this.applyFilter();
    this.getAllVehicles(this.selectedStatus);
    this.populateFilters();
  }
  getMyFilters(userId, selectedFilterId) {
    this.apiService.getSetting(userId, selectedFilterId).subscribe(
      (resultData: any) => {
        // console.log(resultData);
        this.myFilters = resultData;
        // if (this.myFilters.length == 0) {
        // {
        //   this.getAllVehicles(this.selectedStatus);
        // }
        // if (this.myFilters.length > 0) {
        //   this.selectedFilterId = this.myFilters[0].id;
        //   if (localStorage.getItem("selectid")) {
        //     this.selectedFilterId = localStorage.getItem("selectid");
        //     this.getMyFilterData(this.selectedFilterId);
        //   }
        //   else {
        //     this.getMyFilterData(this.myFilters[0].id);
        //   }
        // }
      },
      (error) => { }
    );
  }

  getMyFilterData(selectedFilterId) {
    this.selectedFilterId = selectedFilterId;
    this.AutoCheck_value = [];
    this.carfax_value = [];
    this.odometer_value = [];
    this.autoaccident_value = [];
    this.apiService
      .getSetting(localStorage.getItem("userId"), this.selectedFilterId)
      .subscribe(
        (resultData: any) => {
          localStorage.setItem("selectid", this.selectedFilterId);
          this.selectedMinYear =
            resultData[0].yearFrom != "" ? resultData[0].yearFrom : null;
          this.selectedMake =
            resultData[0].make != "" ? resultData[0].make : null;
          this.selectedModel =
            resultData[0].model != "" ? resultData[0].model : null;
          this.selectedMaxYear =
            resultData[0].yearTo != "" ? resultData[0].yearTo : null;
          this.selectedOdoMin =
            resultData[0].odoMin != "" ? resultData[0].odoMin : null;
          this.selectedOdoMax =
            resultData[0].odoMaX != "" && resultData[0].odoMaX != "0"
              ? resultData[0].odoMaX
              : null;
          // if (resultData[0].inprogress == "1") {
          //   this.savedStatus = "in-progress";
          // } else if (resultData[0].inventry == "1") {
          //   this.savedStatus = "inventory";
          // } else if (resultData[0].ALL == "1") {
          //   this.savedStatus = "all";
          // } else if (
          //   resultData[0].inprogress == "1" &&
          //   resultData[0].inventry == "1" &&
          //   resultData[0].ALL == "1"
          // ) {
          //   this.savedStatus = "all";
          // } else {
          //   this.savedStatus = "all";
          // }
          // resultData[0].odometer.forEach((value, index) => {
            // console.log(value);
          //   this.odometer_value.push(value);
          // });
          // console.log(this.odometer_value);
          // if(this.odometer_value[0] == "Above 100000")
          // {
          //   this.selectedOdoMin = 100000;
          // }
          // else
          // {
          //   var array = this.odometer_value[0].split('-');
            // console.log(array[0],array[1]);
          //   this.selectedOdoMin=array[0];
          //   this.selectedOdoMax=array[1];
          // }

          // if (this.odometer_value[0] != "") {
          //   this.selectedAutocheck = this.odometer_value[0];
          // }

          resultData[0].AutoCheck.forEach((value, index) => {
            // console.log(value);
            this.AutoCheck_value.push(value);
          });

          if (this.AutoCheck_value[0] != "") {
            this.selectedAutocheck = this.AutoCheck_value[0];
          }

          // console.log(this.AutoCheck_value);

          resultData[0].CarFax.forEach((value, index) => {
            this.carfax_value.push(value);
          });
          if (this.carfax_value[0] != "") {
            this.selectedCarfax = this.carfax_value[0];
          }


          // resultData[0].Accidents.forEach((value, index) => {
          //   this.autoaccident_value.push(value);
          // });
          // this.selectedAccidents = this.autoaccident_value[0];
          // console.log("this.selectedStatus", this.selectedStatus);
          this.applyFilter();
          this.getVehicles(this.savedStatus);
          this.populateFilters();
        },
        (error) => { }
      );
  }
}
