import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { ApiService } from "../services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changepasswordForm: FormGroup;
  userId: any;
  loading: boolean = false;
  oldpassword: string;
  onsubmitbtn: boolean = false;
  hide: boolean = true;
  hide_cp:boolean = true;
  expired: boolean ;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.log("param", this.activatedRoute.snapshot.params);
    this.activatedRoute.params.subscribe((params) =>
      this.getActiveRouter(params.Id)
    );
    this.changepasswordForm = this.formBuilder.group({  
      new_pwd: ["", Validators.required],
      confirm_pwd: ["", Validators.required],
    });
  }

  getActiveRouter(Id) {
    console.log("Getid", Id);
    this.userId = Id;
    this.getpasswordDetials(this.userId);
  }
  getpasswordDetials(id) {
    // this.loader = true;
    this.apiService.checkpassword(id).subscribe(
      (resultData: any) => {
        console.log(resultData);
  if(resultData.status == "0")
  {
    this.expired = true;
  }
  else
  {
    this.expired = false;
  }
        
      },
      (error) => {}
    );
  }
  onSubmit() {
    this.onsubmitbtn = true;
    if (this.changepasswordForm.invalid) {
      return;
    }
    if (
      this.changepasswordForm.value.new_pwd ==
      this.changepasswordForm.value.confirm_pwd
    ) {
      let data = {
        userId: this.userId,
        password: this.changepasswordForm.value.confirm_pwd,
      };
      this.loading = true;
      this.apiService.updatepassword(data).subscribe(
        (resultData) => {
          this.loading = false;
          if (resultData["status"] == "1") {
            this.toastr.success(resultData["msg"], null, {
              timeOut: 3000,
            });
            this.router.navigateByUrl("/login");
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
    }
    else
    {
      this.toastr.error("Passwords do not match", null, {
        timeOut: 3000,
      });
    }
  }

  clearMsg() {
    this.onsubmitbtn = false;
  }
}
