import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ApiService } from "../../services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
export interface BOSmail {
  email: string;
}

@Component({
  selector: "app-dealers",
  templateUrl: "./dealers.component.html",
  styleUrls: ["./dealers.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class DealersComponent implements OnInit {
  displayedColumns: string[] = [
    "sno",
    "date",
    "dlrname",
    "addrs",
    "deptcontperson",
    "deptemail",
    "deptcontactph",
    // "bosemailid",
    "editdelete",
  ];
  loggedIn: boolean = false;
  toggleDealerMenu: boolean = true;
  allDealerList: any = [];
  DealerForm: FormGroup;
  dealerid: any ="";
  status: any ="";
  getdealerId:any="";
  addOnBlur = true;
  BOSemails: BOSmail[] = [];
  emailValidBOS = false;
  removable = true;
  loadingDealer:boolean=false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dataSource: MatTableDataSource<any>;
  @ViewChild('scheduledOrdersPaginator') paginator: MatPaginator;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closedelete') closedelete;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private toastr: ToastrService,
    public _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    this.getDealerDetials(0);
    this.DealerForm = this._fb.group({
      DealerID: [""],
      Dealername: [""],
      Dealeraddress: [""],
      City: [""],
      DState: [""],
      Zipcode: [""],
      DealerEmail: [""],
      TDConperson: [""],
      TDEmail: [""],
      TDContacno: [""],

      // BOSOption: [""],
      status: [""],
    });
  }
  getDealerDetials(id) {
    // this.loader = true;
    this.apiService.DealerRequest(id).subscribe(
      (resultData: any) => {
        console.log(resultData);

        this.allDealerList = resultData;
        this.dataSource = new MatTableDataSource(this.allDealerList);
        setTimeout(() => (this.dataSource.paginator = this.paginator));
      },
      (error) => {}
    );
  }
  // ngAfterViewInit() {
  //   setTimeout(() => (this.dataSource.paginator = this.paginator));
  // }
  logout() {
    this.authService.logout();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our BOSmail
    if ((value || '').trim()) {
      var reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      if (reg.test(value)) {  
       this.emailValidBOS = false;
       console.log(value.trim());
        this.BOSemails.push({email: value.trim()});
      } else {
        // alert('please enter valid email');
        this.emailValidBOS = true;
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(BOSmail: BOSmail): void {
    const index = this.BOSemails.indexOf(BOSmail);

    if (index >= 0) {
      this.BOSemails.splice(index, 1);
    }
  }
  editdearler(id) {
    this.apiService.DealerRequest(id).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.DealerForm.get("Dealername").setValue(resultData[0].Dealername);
        this.DealerForm.get("Dealeraddress").setValue(
          resultData[0].Dealeraddress
        );
        this.DealerForm.get("City").setValue(resultData[0].City);
        this.DealerForm.get("DState").setValue(resultData[0].DState);
        this.DealerForm.get("Zipcode").setValue(resultData[0].Zipcode);
        this.DealerForm.get("DealerEmail").setValue(resultData[0].DealerEmail);
        this.DealerForm.get("TDConperson").setValue(resultData[0].TDConperson);
        this.DealerForm.get("TDEmail").setValue(resultData[0].TDEmail);
        this.DealerForm.get("TDContacno").setValue(resultData[0].TDContacno);
        this.dealerid = resultData[0].DealerID;
        this.status = resultData[0].status;
        if(resultData[0].EmailBos != "" && resultData[0].EmailBos != null)
        {
          var array = resultData[0].EmailBos.split(',');
          array.forEach((value, index) => {
          console.log(value.name);
          this.BOSemails.push({email: value.trim()});
        });
          // this.BOSemails.push({email: resultData[0].EmailBos});
        }
      },
      (error) => {}
    );
  }
  applyFilter(FilterValue: string) {
    this.dataSource.filter = FilterValue.trim().toLowerCase();
    
  }
  Onsubmit() {
    this.loadingDealer=true;
    let data = {
      // Sno:(this.Sno!="")?this.Sno:"",
      DealerID: this.dealerid !=""?this.dealerid:"",
      Dealername: this.DealerForm.value.Dealername,
      Dealeraddress: this.DealerForm.value.Dealeraddress,
      City: this.DealerForm.value.City,
      DState: this.DealerForm.value.DState,
      Zipcode: this.DealerForm.value.Zipcode,
      DealerEmail:this.DealerForm.value.DealerEmail,
      TDConperson: this.DealerForm.value.TDConperson,
      TDEmail: this.DealerForm.value.TDEmail,
      TDContacno: this.DealerForm.value.TDContacno,
      EmailBos:this.BOSemails,
      BOSOption: "",
      status: this.status !=""?this.status:"",
      
    };

    this.apiService.Dealearupdate(data).subscribe((resultData: any) => {
      this.loadingDealer=false;
      console.log(resultData);
      if (resultData.status == "1") {
        this.toastr.success(resultData.msg, null, {
          timeOut: 3000,
        });
      }
      if (resultData.status == "0") {
        this.toastr.error(resultData.msg, null, {
          timeOut: 3000,
        });
      }
      this.BOSemails=[];
      this.DealerForm.reset();
      this.getDealerDetials(0);
      //this.modal.close();

      this.closebutton.nativeElement.click();
    },(error) => {});
  }
  getDealerId(id)
  {
   this.getdealerId = id;
  }
  Deletedealer() {
    console.log(this.getdealerId);
    this.getdealerId = (this.getdealerId != "")?this.getdealerId:"";
    this.apiService.deletedealer(this.getdealerId).subscribe(
      (resultData: any) => {
        if (resultData.status == "1") {
          this.toastr.success("Deleted successfully", null, {
            timeOut: 3000,
          });
          this.closedelete.nativeElement.click();
          this.getDealerDetials(0);
      
  
        }
      },
      (error) => {}
    );
  }
  showDlrMenu() {
    this.toggleDealerMenu = !this.toggleDealerMenu;
  }
}
